<template>
  <div style="padding: 20px" v-loading.fullscreen.lock="pageLoading"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.7)">
    <el-table :data="characters"
      :empty-text="emptyText"
      :header-cell-style="rowClass"
      height="calc(100vh - 230px)">
      <el-table-column prop="id" label="帳號"></el-table-column>
      <el-table-column prop="password" label="密碼"></el-table-column>
      <el-table-column prop="character" label="權限"></el-table-column>
      <el-table-column prop="edit" label="操作" width="200">
        <template slot-scope="scope">
          <el-button type="primary" size="small" style="margin-bottom: 0 !important;" @click="editCharac(scope.row)" plain>編輯</el-button>
          <el-button type="danger" size="small" style="margin-bottom: 0 !important;" @click="checkDel(scope.row)" plain>刪除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="text-align: right; margin-top: 10px;">
      <el-button type="primary" style="margin: 0 !important;" @click="addCharacDia = true; type = 'add';">新增人員</el-button>
    </div>
    <mu-dialog :open="deleteDia" width="350">
      <div>確定要刪除 {{ deleteId }} 嗎？</div>
      <div style="margin-top: 20px;">
        <el-row :gutter="10">
          <el-col :span="12">
            <el-button type="info" plain
              style="width: 100%; margin: 0 !important;"
              @click="deleteDia = false">返回</el-button>
          </el-col>
          <el-col :span="12">
            <el-button type="primary" plain
              style="width: 100%; margin: 0 !important;"
              @click="sendDeleteCharac">確認</el-button>
          </el-col>
        </el-row>
      </div>
    </mu-dialog>
    <mu-dialog :open="addCharacDia" :title="type === 'add' ? '新增人員' : '編輯人員'" width="350">
      <div>
        <mu-text-field v-model.trim="characData.id" :disabled="type === 'edit'" :error-text="errorText" full-width label="帳號"></mu-text-field><br/>
        <mu-text-field v-model.trim="characData.password" full-width label="密碼"></mu-text-field><br/>
        <mu-select label="權限" v-model="characData.character" full-width>
          <mu-option v-for="(option, index) in options" :key="index" :label="option" :value="option"></mu-option>
        </mu-select>
      </div>
      <div>
        <el-row :gutter="10">
          <el-col :span="12">
            <el-button type="info" plain
              style="width: 100%; margin: 0 !important;"
              @click="addCharacDia = false">返回</el-button>
          </el-col>
          <el-col :span="12">
            <el-button type="primary" plain
              :disabled="checkable"
              style="width: 100%; margin: 0 !important;"
              @click="sendAddCharac">確認</el-button>
          </el-col>
        </el-row>
      </div>
    </mu-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      type: 'add',
      pageLoading: false,
      addCharacDia: false,
      deleteDia: false,
      errorText: '',
      emptyText: '載入中...',
      characters: [],
      deleteId: '',
      characData: {
        id: '',
        password: '',
        character: ''
      },
      options: ['管理員', 'A區編輯', 'A區客服', 'B區編輯', 'B區客服']
    }
  },
  mounted() {
    this.getAllCharac();
  },
  computed: {
    checkable() {
      if (this.characData.id && this.characData.password && this.characData.character) {
        return false;
      }
      return true;
    }
  },
  methods: {
    ...mapActions({
      getAllCharacters: 'getAllCharacters',
      setCharacter: 'setCharacter',
      removeCharacter: 'removeCharacter'
    }),
    rowClass({ row, rowIndex }) {
      return 'background-color: #eee; color: #555'
    },
    getAllCharac() {
      this.getAllCharacters()
        .then((_characs) => {
          this.pageLoading = false;
          this.emptyText = '暫無資料';
          this.characters = _characs;
        })
    },
    checkDel(row) {
      this.deleteDia = true;
      this.deleteId = row.id;
    },
    sendDeleteCharac() {
      this.deleteDia = false;
      this.pageLoading = true;
      this.removeCharacter({ id: this.deleteId })
        .then(() => {
          this.getAllCharac();
        })
    },
    editCharac(row) {
      this.type = 'edit';
      this.addCharacDia = true;
      this.characData = row;
    },
    sendAddCharac() {
      if (this.type === 'add' && this.characters.map(el => el.id).indexOf(this.characData.id) !== -1) {
        this.errorText = '已有相同的帳號！';
      }
      else {
        this.addCharacDia = false;
        this.errorText = '';
        this.pageLoading = true;
        this.setCharacter(this.characData)
          .then(() => {
            this.getAllCharac();
          })
      }
    }
  }
}
</script>

<style>

</style>