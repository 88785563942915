<template>
  <div style="padding: 20px" v-loading.fullscreen.lock="pageLoading"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.7)">
    <div style="text-align: left;" v-if="clientList && clientList.length > 0">
      <el-row :gutter="10">
        <el-col :span="6">
          <el-select v-model="client" placeholder="請選擇品牌" @change="clearData" style="width: 100%;">
            <el-option v-for="item in clientList"
              :key="item.clientId"
              :label="item.name"
              :value="JSON.stringify(item)">
            </el-option>
          </el-select>
        </el-col>
        <!-- 會員電話 -->
        <el-col :span="6">
          <el-input v-model="user.phone" :disabled="!client" placeholder="請輸入會員電話"></el-input>
        </el-col>
        <!-- 會員編號 -->
        <el-col :span="6">
          <el-input v-model="user.userId" :disabled="!client" placeholder="請輸入會員編號"></el-input>
        </el-col>
        <!-- 會員編號 -->
        <el-col :span="6">
          <el-button type="primary" icon="el-icon-search" @click="queryMembr">搜尋</el-button>
        </el-col>
      </el-row>
    </div>
    <hr />
    <div class="userArea">
      <el-row>
        <el-col :span="6" style="border-right: 1px solid; text-align: left;">
          <h3>姓名</h3>
          <h3>生日</h3>
          <h3>Email</h3>
          <h3>性別</h3>
          <h3>地址</h3>
          <h3>等級</h3>
          <h3>櫃位</h3>
          <h3>LINE ID</h3>
          <h3>FB ID</h3>
          <h3>是否為 EILIS 會員</h3>
        </el-col>
        <el-col :span="18" style="padding-left: 20px; text-align: left;" v-if="isUser">
          <h3><span style="color: #409EFF;">{{ user.name }}</span></h3>
          <h3><span style="color: #409EFF;">{{ user.birth }}</span></h3>
          <h3><span style="color: #409EFF;">{{ user.email ? user.email : '-' }}</span></h3>
          <h3><span style="color: #409EFF;">{{ user.gender }}</span></h3>
          <h3><span style="color: #409EFF;">{{ user.address ? user.address : '-' }}</span></h3>
          <h3><span style="color: #409EFF;">{{ user.cardkind }}</span></h3>
          <h3><span style="color: #409EFF;">{{ user.saleroom }}</span></h3>
          <h3><span style="color: #409EFF;">{{ user.lineid ? user.lineid : '-' }}</span></h3>
          <h3><span style="color: #409EFF;">{{ user.fbid ? user.fbid : '-' }}</span></h3>
          <h3>
            <i v-if="isEilis" class="el-icon-success" style="color: #45db3d; font-size: 20px; transform: translateY(1px);"></i>
            <i v-else class="el-icon-error" style="color: #f24; font-size: 20px; transform: translateY(1px);"></i>
          </h3>
        </el-col>
      </el-row>
    </div><hr style="margin-bottom: 15px;"/>
    <el-button style="width: 100%;" type="primary" @click="addToEilis" :disabled="!isUser">加入 EILIS 會員</el-button>
    <mu-dialog :open="selectPlatform" width="400">
      <mu-select label="請選擇註冊平台" v-model="user.service" style="margin-bottom: 0 !important;">
        <mu-option v-for="option in services" :key="option" :label="option" :value="option"></mu-option>
      </mu-select>
      <mu-button slot="actions" flat color="primary" @click="selectPlatform = false">關閉</mu-button>
      <mu-button slot="actions" color="primary" @click="sendRegister" :disabled="!user.service">送出</mu-button>
    </mu-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      client: '',
      pageLoading: false,
      selectPlatform: false,
      user: {
        clientId: '',
        fbid: '',
        lineid: '',
        service: '',
        token: '',
        phone: '',
        email: '',
        name: '',
        birth: '',
        gender: '',
        cardkind: '',
        saleroom: '',
        address: '',
        userId: ''
      },
      services: ['FB', 'LINE'],
      isUser: false,
      isEilis: false
    };
  },
  mounted() {
    if (this.clientList && this.clientList.length > 0) {
      // do something
    }
    else {
      this.getBrands();
    }
  },
  computed: {
    ...mapGetters({
      clientList: 'clients'
    }),
  },
  methods: {
    ...mapActions({
      getBrands: 'getBrands',
      getUserByPhone: 'getUserByPhone',
      register: 'register',
      updateUser: 'updateUser',
      queryRuentexMember: 'queryRuentexMember'
    }),
    clearData(val) {
      // console.log(val);
    },
    sendRegister() {
      this.selectPlatform = false;
      if (this.user.service === 'LINE') {
        this.user.token = this.user.lineid;
      }
      else {
        this.user.token = this.user.fbid;
      }
      this.pageLoading = true;
      this.register(this.user).then((res) => {
        this.pageLoading = false;
        if (res.status === '10020') {
          this.$notify({
            title: '註冊成功',
            type: 'success'
          });
          this.updateUser(this.user);
        }
        else {
          this.$notify({
            title: '註冊失敗',
            type: 'error'
          });
        }
      })
    },
    addToEilis() {
      if (this.isEilis) {
        this.$notify({
          title: '此會員已經是 EILIS 會員',
          type: 'warning'
        });
      }
      else {
        this.selectPlatform = true;
      }
    },
    queryMembr() {
      const postData1 = {
        brand: JSON.parse(this.client).title,
        phone: this.user.phone,
        custid: this.user.userId
      };
      const postData2 = {
        enviro: 'pro',
        clientId: JSON.parse(this.client).clientId,
        phone: this.user.phone
      }
      let promises = [];
      // 取得潤泰會員
      promises.push(this.queryRuentexMember(postData1));
      // 取得 EILIS 會員
      promises.push(this.getUserByPhone(postData2));
      // ----
      this.pageLoading = true;
      this.isUser = false;
      Promise.all(promises).then((_result) => {
        this.pageLoading = false;
        if (!_result[0].result.custid) {
          this.$notify({
            title: '查無此會員',
            type: 'warning'
          });
        }
        else {
          this.isUser = true;
          const ruentexUser = _result[0].result;
          this.user.clientId = JSON.parse(this.client).clientId;
          this.user.fbid = ruentexUser.fbid;
          this.user.lineid = ruentexUser.lineid;
          this.user.email = ruentexUser.email;
          this.user.name = ruentexUser.name;
          this.user.birth = ruentexUser.birth;
          this.user.gender = ruentexUser.gender;
          this.user.cardkind = ruentexUser.cardkind;
          this.user.saleroom = ruentexUser.saleroom;
          this.user.address = ruentexUser.address;
        }
        this.isEilis = _result[1].status === '10020' ? true : false;
      })
    }
  },
}
</script>

<style scoped>
  .userArea {
    height: calc(100vh - 270px);
    overflow-y: auto;
    width: 100%;
    max-height: 430px;
  }
</style>