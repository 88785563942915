<template>
  <div class="container2">
    <el-timeline>
      <el-timeline-item timestamp="2020/11/30" placement="top">
        <el-card>
          <h3 style="margin-top: 5px; color: #545C64;">新功能</h3>
          <ul>
            <li style="margin-bottom: 10px; padding-left: 7px;">修改密碼</li>
            <li style="margin-bottom: 10px;">【 查詢會員資料 】增加「贈送點數」功能</li>
          </ul>
        </el-card>
      </el-timeline-item>
      <el-timeline-item timestamp="2020/11/11" placement="top">
        <el-card>
          <h3 style="margin-top: 5px; color: #545C64;">欄位調整</h3>
          <ul>
            <li style="margin-bottom: 10px;">【 編輯商城品項 】增加顯示「上下架時間」</li>
          </ul>
        </el-card>
      </el-timeline-item>
    </el-timeline>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
    };
  },
  methods: {
    ...mapActions({
    })
  }
}
</script>

<style scoped>
  .container2 {
    text-align: left;
    padding: 20px;
    padding-right: 50px;
    padding-top: 30px;
    height: calc(100vh - 300px);
    overflow: auto;
  }
</style>