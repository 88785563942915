const random = new Array('0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z');


export function generateCode(number) {
    let code = '';
    for (let i = 0; i < number; i += 1) {
        const position = Math.floor(Math.random() * 36);
        code += random[position];
    }
    return code;
}