import Vue from 'vue';
import Vuex from 'vuex';
// import menu from './modules/menu';
// import preloader from './modules/preloader';
import member from './service';


// 設定環境全域變數設計，切換開發模式與產品部署模式
// import SiteConfig from './config';

// Object.defineProperty(Vuex.Store.prototype, '$conf', { value: SiteConfig });


Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        member,
    },
});

export default store;