<template>
  <div class="about">
    <mu-appbar color="#555" style="padding-left: 0;">
      <el-row>
        <el-col :span="6" style="text-align: left;">
          <mu-button icon @click="showMenuList">
            <i class="el-icon-s-unfold"></i>
          </mu-button>
        </el-col>
        <el-col :span="12">
          <div style="font-weight: 600; font-size: 20px;">潤泰管理後台</div>
        </el-col>
        <el-col :span="6" style="text-align: right;">
          <el-dropdown @command="handleCommand" trigger="click">
            <span class="el-dropdown-link" style="margin-right: 10px;">
              <i class="el-icon-user-solid profile"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="0">
                <span style="margin-right: 10px;">{{ character.id }}</span>
                <el-tag type="danger">{{ character.character }}</el-tag>
              </el-dropdown-item>
              <el-dropdown-item command="2">登出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
      </el-row>
    </mu-appbar>
    <!-- 選單 -->
    <el-row>
      <el-col :span="col('menu')" >
        <el-menu style="text-align: left; font-weight: 600; height: calc(100vh - 64px); overflow: auto;"
          @select="getIndex"
          :default-active="nowPage"
          :collapse="false"
          background-color="#545c64"
          text-color="#fff"
          active-text-color="#ffd04b"
          class="el-menu-vertical-demo"
          :unique-opened="true">
          <el-menu-item v-for="(page, idx) in pageTabs"
            v-show="allowEdit(page.route)"
            :index="page.name"
            :key="idx + 'p'"
            @click="toPage(page)">
            <div :class="{ active: nowPage === page.name, noactive: nowPage !== page.name }">
              {{ page.name }}
            </div>
          </el-menu-item>
          <!-- GDS -->
          <el-submenu index="6" v-if="gaList.length > 0">
            <template slot="title">數據分析</template>
            <el-submenu v-for="(client, index) in gaList"
              :index="`6-${index + 1}`"
              :key="client.brand">
              <template slot="title">
                <span>{{ client.brand }}</span>
              </template>
              <el-menu-item v-for="(sheet, idx) in client.list"
                :index="`6-${index + 1}-${idx + 1}`"
                :key="sheet.name + index">
                {{ sheet.name }}
              </el-menu-item>
            </el-submenu>
          </el-submenu>
          <!-- 修改密碼
          <el-menu-item index="9" v-if="allowEdit(null)">
            <span slot="title">修改密碼</span>
          </el-menu-item> -->
          <!-- 開發人員專區 -->
          <el-submenu index="10" v-if="allowEditAdmin()">
            <template slot="title">開發人員專區</template>
            <el-menu-item index="10-1">
              <span slot="title">開通品牌</span>
            </el-menu-item>
            <el-menu-item index="10-2">
              <span slot="title">設定內嵌報表</span>
            </el-menu-item>
            <el-menu-item index="10-3">
              <span slot="title">會員資料補登</span>
            </el-menu-item>
            <el-menu-item index="10-4">
              <span slot="title">人員權限設定</span>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-col>
      <el-col :span="col('page')" style="position: relative; height: calc(100vh - 64px); overflow: auto;">
        <router-view></router-view>
        <!-- LOGO -->
        <div v-if="nowPage[0] !== '6' && nowPage[0] !== '10'" style="position: absolute; bottom: 10px; left: 10px; text-align: left;">
          <img src="https://i.imgur.com/SITKieL.png" width="120"/>
          <div style="padding-left: 12px; font-size: 13px;">Copyright © 2020 埃立思科技股份有限公司 版權所有</div>
        </div>
      </el-col>
    </el-row>
    <!-- 修改密碼 -->
    <mu-dialog :open="changePassDia" @close="closePassDia" title="修改密碼">
      <mu-text-field type="password" v-model="password" :error-text="wrongPass" placeholder="請輸入舊密碼"></mu-text-field><br/>
      <mu-text-field type="password" v-model="newPass" placeholder="請輸入新密碼"></mu-text-field><br/>
      <el-button type="primary" style="width: 100%;" :loading="isLoading" @click="confirmChange">
        <span v-if="!isLoading">確認</span>
      </el-button>
    </mu-dialog>
    <!-- 開發人員專區 -->
    <mu-dialog :open="codeDia" @close="closePassDia" title="登入密碼">
      <mu-text-field type="password" style="margin-bottom: 0;" :error-text="wrongPass" v-model="developCode" placeholder="請輸入開發人員代碼"></mu-text-field><br/>
      <el-button type="primary" style="width: 100%; margin-top: 20px;" @click="loginDevelop">確認</el-button>
    </mu-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions } from 'vuex';


export default {
  data() {
    return {
      showMenu: false,
      pageTabs: [
        { name: '更新日誌', route: 'changeLog' },
        { name: '編輯商城品項', route: 'itemList' },
        { name: '管理優惠券序號', route: 'manageCoupon' },
        { name: '抽獎遊戲', route: 'lottery' },
        { name: '問卷模組', route: 'form' },
        { name: '查詢會員資料', route: 'queryMember' },
        { name: '批次贈送點數', route: 'sendPoint' },
        { name: '查詢交易紀錄', route: 'transactionRecord' },
        { name: '查詢兌換紀錄', route: 'exchangeRecord' },
        { name: '分眾標籤', route: 'tag' },
        { name: '店櫃資訊', route: 'salerooms' },
      ],
      gaList: [],
      iframeUrl: '',
      password: '',
      newPass: '',
      wrongPass: '',
      isLoading: false,
      changePassDia: false,
      passwordDia: true,
      developPage: '',
      developCode: '',
      codeDia: false,
      character: {
        character: '',
        id: ''
      },
      nowPage: '更新日誌',
    };
  },
  mounted() {
    const loginData = localStorage.getItem('loginData');
    if (loginData) {
      // 設定顯示權限
      this.character = JSON.parse(loginData);
      // 分頁
      if (window.location.pathname === '/settingGDS' || window.location.pathname === '/develop' || window.location.pathname === '/addMember'|| window.location.pathname === '/character') {
        this.$router.push('/');
      }
      else {
        const path = window.location.pathname.replace('/', '');
        if (path) {
          const pathname = this.pageTabs.filter(el => el.route === path)[0].name;
          this.nowPage = pathname;
        }
        else {
          this.nowPage = '更新日誌';
        }
      }
      this.getBrands();
      this.getIframe().then((_res) => {
        this.gaList = _res ? Object.values(_res) : [];
      })
    }
    else {
      this.$router.push('/login');
    }
  },
  methods: {
    ...mapActions({
      getIframe: 'getIframe',
      getBrands: 'getBrands',
      changePassword: 'changePassword',
      checkPassword: 'checkPassword'
    }),
    showMenuList() {
      this.showMenu = !this.showMenu;
    },
    handleCommand(val) {
      if (val === '2') {
        localStorage.clear();
        this.$router.push('/login');
      }
    },
    allowEditAdmin() {
      if (this.character.character === '管理員') {
        return true;
      }
      return false;
    },
    allowEdit(tab) {
      if (this.character.character === 'A區客服' || this.character.character === 'B區客服') {
        if (tab === 'queryMember' || tab  === 'transactionRecord') {
          return true;
        }
        return false;
      }
      return true;
    },
    toPage(page) {
      this.nowPage = page.name;
      const path = window.location.pathname.replace('/', '');
      if (page && page.route !== path) {
        this.$router.push(`/${page.route}`);
      }
    },
    getIndex(idx) {
      this.nowPage = idx;
      if (idx !== '9' && idx !== '10-1' && idx !== '10-2' && idx !== '10-3' && idx !== '10-4') {
        localStorage.setItem('nowPosition', idx);
      }
      if (idx[0] === '6') {
        this.$router.push(`/chart/${idx}`);
      }
      if (idx === '9') {
        this.password = '';
        this.changePassDia = true;
      }
      if (idx === '10-1' || idx === '10-2' || idx === '10-3' || idx === '10-4') {
        this.developPage = idx;
        this.developCode = '';
        this.codeDia = true;
      }
    },
    checkPass() {
      this.wrongPass = '';
      this.isLoading = true;
      const postData = { password: this.password };
      this.checkPassword(postData).then((_msg) => {
        this.isLoading = false;
        if (_msg === '登入成功') {
          this.wrongPass = '';
          this.passwordDia = false;
        }
        else {
          this.wrongPass = '密碼輸入錯誤';
        }
      })
    },
    closePassDia() {
      this.codeDia = false;
      this.changePassDia = false;
      this.nowPage = localStorage.getItem('nowPosition') ? localStorage.getItem('nowPosition') : '更新日誌';
    },
    confirmChange() {
      this.isLoading = true;
      this.wrongPass = '';
      const postData = { password: this.password, newPass: this.newPass };
      this.changePassword(postData).then((_msg) => {
        this.password = '';
        this.isLoading = false;
        if (_msg === '修改密碼成功') {
          this.changePassDia = false;
          this.wrongPass = '';
          this.$notify({
            title: '修改密碼成功，請重新登入',
            type: 'success'
          });
          this.passwordDia = true;
          this.nowPage = localStorage.getItem('nowPosition') ? localStorage.getItem('nowPosition') : '更新日誌'
        }
        else {
          this.wrongPass = _msg;
        }
      })
    },
    loginDevelop() {
      if (this.developCode === '23055068') {
        if (this.nowPage === '10-1') {
          this.$router.push('/develop');
        }
        if (this.nowPage === '10-2') {
          this.$router.push('/settingGDS');
        }
        if (this.nowPage === '10-3') {
          this.$router.push('/addMember');
        }
        if (this.nowPage === '10-4') {
          this.$router.push('/character');
        }
        this.codeDia = false;
      }
      else {
        this.wrongPass = '輸入的密碼有誤！'
      }
    },
    col(val) {
      if (val === 'menu') {
        if (this.showMenu) {
          return 5;
        }
        else {
          return 0;
        }
      }
      else {
        if (this.showMenu) {
          return 19;
        }
        else {
          return 24;
        }
      }
    },
  }
}
</script>

<style scoped>
  .about {
    margin: 0 auto;
  }
  .active {
    color: #fcce03;
  }
  .noactive {
    color: #fff;
  }
  .profile {
    color: #fff;
    font-size: 20px;
    cursor: pointer;
  }
</style>