<template>
  <div style="padding: 20px; min-width: 750px" v-loading.fullscreen.lock="pageLoading"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.7)">
    <!-- 選擇環境品牌 -->
    <div id="enviro">
      <el-row :gutter="10" style="max-width: 1000px;">
        <el-col :span="8">
          <el-select style="width: 100%;"
            v-model="enviro"
            placeholder="請選擇環境"
            @change="clearData">
            <el-option v-for="item in enviros"
              :key="item.value"
              :label="item.name"
              :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="8">
          <el-select style="width: 100%;"
            @change="clearData"
            v-model="client"
            placeholder="請選擇品牌">
            <el-option v-for="item in clientList"
              :key="item.clientId"
              :label="item.name"
              :value="item.clientId">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="4" style="text-align: left; margin-top: 10px;">
          <label style="padding: 10px 20px; font-size: 14px; border-radius: 4px; cursor: pointer; background-color: #409EFF; color: #fff;">
            <i class="el-icon-upload2" style="margin-right: 5px;"></i>
            <input style="display:none;" type="file" @change="transformFile">上傳檔案
          </label>
        </el-col>
        <el-col :span="4" style="text-align: left;">
          <el-button type="info" @click="showExample = true">範例</el-button>
        </el-col>
      </el-row>
    </div>
    <!-- 名單列表 -->
    <div>
      <el-table :data="sheetUser"
        height="calc(100vh - 370px)"
        :header-cell-style="tableHeaderColor"
        :empty-text="emptyText"
        style="width: 100%">
        <el-table-column
          prop="userId"
          label="會員編號">
        </el-table-column>
        <el-table-column
          prop="point"
          label="贈送點數">
        </el-table-column>
        <el-table-column
          width="90">
          <template slot-scope="scope">
            <el-button type="danger"
              @click="deleteUser(scope.$index)"
              style="margin: 0 !important;"
              size="small">刪除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 點數 -->
    <!-- <div style="margin-top: 10px;">
      <el-input type="text"
        placeholder="請輸入欲贈送點數"
        v-model.number="point">
      </el-input>
    </div> -->
    <!-- 備註欄 -->
    <div style="margin-top: 10px;">
      <el-input type="textarea"
        maxlength="50"
        show-word-limit
        :rows="2"
        placeholder="請輸入贈點備註，上限 50 字"
        v-model="remark">
      </el-input>
    </div>
    <!-- 按鈕 -->
    <div style="margin-top: 10px;">
      <el-button style="width: 100%;"
        :disabled="sheetUser.length < 1"
        @click="sendPoint"
        type="primary">送出
      </el-button>
    </div>
    <!-- dialog -->
    <el-dialog title="上傳格式範例"
      width="480px"
      :visible.sync="showExample"
      :close-on-click-modal="false">
      <div style="text-align: left;">
        <p style="font-size: 15px; margin-top: 0;">備註：檔案格式必須為.xlsx，每次匯入的名單上限為 <b>500</b> 人</p>
        <img src="https://i.imgur.com/FG89iAb.png" width="80%">
        <el-button style="width: 100%; margin-top: 10px;"
          type="warning"
          @click="export2Excel"
          icon="el-icon-download">下載範例檔
        </el-button>
      </div>
    </el-dialog>
    <!-- dialog -->
    <el-dialog title="確認贈點資訊"
      width="480px"
      :visible.sync="showInfo"
      :close-on-click-modal="false">
      <div v-if="client" class="infoBox">
        <el-row>
          <el-col :span="6">
            <h3 style="text-align: left;">品牌</h3>
            <h3 style="text-align: left;">人數</h3>
            <h3 style="text-align: left;">備註</h3>
          </el-col>
          <el-col :span="18">
            <h3 style="color: #409EFF; text-align: left;">{{ brandName }}</h3>
            <h3 style="color: #409EFF; text-align: left;">{{ sheetUser.length }}</h3>
            <h3 style="color: #409EFF; text-align: left;">{{ remark ? remark : '無' }}</h3>
          </el-col>
        </el-row>
      </div>
      <el-button style="width: 100%;"
        @click="checkSend"
        type="primary">確定
      </el-button>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import XLSX from 'xlsx';
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      pageLoading: false,
      allowSend: false,
      showExample: false,
      showInfo: false,
      enviro: '',
      client: '',
      emptyText: '尚無資料',
      point: 0,
      remark: '',
      sheetUser: [],
      sheetData: [
        { 'userId':'ELS000000024', 'point': 5 },
        { 'userId':'ELS000000016', 'point': 10 },
      ],
      enviros: [
        { value: 'pro', name: '正式站' },
        { value: 'test', name: '測試站' }
      ],
    };
  },
  mounted() {
    if (this.clientList && this.clientList.length > 0) {
      // do something
    }
    else {
      this.getBrands();
    }
  },
  computed: {
    ...mapGetters({
      clientList: 'clients'
    }),
    brandName() {
      if (this.client) {
        return this.clientList.filter(el => el.clientId === this.client)[0].name;
      }
      return '';
    }
  },
  methods: {
    ...mapActions({
      getBrands: 'getBrands',
      sendPointsInStream: 'sendPointsInStream'
    }),
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return 'background-color: #eee; font-weight: 500; color: #444;'
      }
    },
    showNotify(title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },
    export2Excel() {
      require.ensure([], () => {
        const { export_json_to_excel } = require('./excel/Export2Excel');
        const tHeader = ['userId', 'point'];  // 设置 Excel 的表格第一行的标题
        const filterVal = ['userId', 'point'];  // tableData 里对象的属性
        const list = this.sheetData;  //把 data 里的 tableData 存到 list
        const data = this.formatJson(filterVal, list);
        export_json_to_excel(tHeader, data, 'user_sample');  //导出 Excel 文件名
      })
    },
    transformFile(e) {
      e.preventDefault();
      let file = e.target.files;
      if (file.length <= 0) { // 如果沒有檔名
        return;
      } 
      else if (!/\.(xls|xlsx)$/.test(file[0].name.toLowerCase())) {
        this.showNotify('', '上傳格式不正確，請上傳xls或者xlsx格式', 'error');
        return;
      }
      let fileReader = new FileReader();
      if (typeof fileReader === 'undefined') {
        this.showNotify('', '您的瀏覽器不支援FileReader介面', 'error');
        return
      }
      var vm = this;
      vm.sheetUser = [];
      vm.emptyText = '上傳資料中...';
      fileReader.onload = function(ev) {
        try {
          var data = ev.target.result,
          workbook = XLSX.read(data, { type: 'binary' }), // 以二进制流方式读取得到整份excel表格对象
          persons = []; // 存储获取到的数据
        } 
        catch (e) {
          return;
        }
        var fromTo = '';
        for (var sheet in workbook.Sheets) {
          if (workbook.Sheets.hasOwnProperty(sheet)) {
            fromTo = workbook.Sheets[sheet]['!ref'];
            persons = persons.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
            break;
          }
        }
        if (persons.length <= 500) {
          vm.sheetUser = persons;
          vm.fileName = file[0].name;
          vm.showNotify('上傳成功', '', 'success');
        }
        else {
          vm.showNotify('', '上傳名單不能大於 1000 筆', 'error');
        }
        vm.emptyText = '尚無資料';
      }
      // 以二进制方式打开文件
      fileReader.readAsBinaryString(file[0]);
    },
    deleteUser(index) {
      this.sheetUser.splice(index, 1);
    },
    clearData() {
      this.sheetUser = [];
    },
    checkSend() {
      this.showInfo = false;
      this.pageLoading = true;
      const postData = {
        enviro: this.enviro,
        clientId: this.client,
        userIds: this.sheetUser,
        remark: this.remark
      };
      this.sendPointsInStream(postData).then(() => {
        this.pageLoading = false;
        this.showNotify('贈點完成', '', 'success');
      })
    },
    sendPoint() {
      this.showInfo = true;
    }
  }
}
</script>

<style scoped>
  #enviro {
    width: 750px;
  }
  .infoBox {
    border: 1px solid #999;
    border-radius: 5px;
    padding: 0 20px;
    margin-bottom: 15px;
  }
</style>