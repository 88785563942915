<template>
  <div>
    <!-- 選擇 client -->
    <div v-if="!openEdit" style="padding: 20px; padding-bottom: 30px;">
      <div style="text-align: left;">
        <el-select style="margin-right: 10px;" v-model="enviro" placeholder="請選擇環境" @change="client = ''; itemLists = []; typeList = [];">
          <el-option v-for="item in enviros"
            :key="item.value"
            :label="item.name"
            :value="item.value">
          </el-option>
        </el-select>
        <el-select style="margin-right: 10px;" v-model="client" placeholder="請選擇品牌" @change="getItem">
          <el-option v-for="item in clientList"
            :key="item.clientId"
            :label="item.name"
            :value="item.clientId">
          </el-option>
        </el-select>
        <el-select style="margin-right: 10px;" v-if="client" v-model="type" placeholder="請選擇分類">
          <el-option v-for="(t, index) in typeList"
            :key="index + 't'"
            :label="t"
            :value="t">
          </el-option>
        </el-select>
        <el-button v-if="client" type="primary" @click="addDia = true" style="margin-bottom: 0 !important;" plain>新增</el-button>
        <el-button v-if="client" type="danger" @click="sortDia = true" style="margin: 0 !important;" plain>調整順序</el-button>
      </div>
      <div style="text-align: right;">
        <span v-if="itemLists.length < 50">{{ itemLists.length }}/50</span>
        <span v-else style="color: #f24">{{ itemLists.length }}/50</span>
      </div>
      <mu-dialog :open.sync="sortDia" title="拖移調整順序" width="350">
        <div style="height: 50vh; overflow: auto;">
          <SlickList lockAxis="y" axis="y" v-model="typeList" :lockToContainerEdges="true" :distance="10">
            <SlickItem v-for="(t, index) in typeList" :index="index" :key="index + 't'">
              <!-- <el-button icon="el-icon-rank" type="primary" plain>{{ t }}</el-button> -->
              <div style="cursor: pointer; margin-bottom: 5px;">{{ t }}</div>
            </SlickItem>
          </SlickList>
        </div>
      </mu-dialog>
      <!-- 品項列表 -->
      <el-table :data="showItem" :header-cell-style="tableHeaderColor"
        :key="type"
        height="calc(100vh - 270px)"
        empty-text="暫無資料">
        <!-- 圖片 -->
        <el-table-column label="圖片" width="100">
          <template slot-scope="scope">
            <div :style="{ height: 80 + 'px', backgroundImage: 'url(' + scope.row.url + ')', backgroundSize: 'cover' }"></div>
          </template>
        </el-table-column>
        <!-- 名稱 -->
        <el-table-column label="活動名稱" width="160">
          <template slot-scope="scope"><h4 style="color: #466594; margin: 0;">{{ scope.row.title }}</h4></template>
        </el-table-column>
        <!-- 優惠案號 -->
        <el-table-column label="優惠案號" width="130">
          <template slot-scope="scope"><h4>{{ scope.row.itemNo }}</h4></template>
        </el-table-column>
        <!-- 上架時間 -->
        <el-table-column label="上架時間" width="130">
          <template slot-scope="scope"><h4>{{ formatDate(scope.row.onshelf) }}</h4></template>
        </el-table-column>
        <!-- 上架時間 -->
        <el-table-column label="下架時間" width="130">
          <template slot-scope="scope"><h4>{{ formatDate(scope.row.offshelf) }}</h4></template>
        </el-table-column>
        <!-- 所需點數 -->
        <el-table-column label="所需點數">
          <template slot-scope="scope">
            <h4>{{ scope.row.point ? scope.row.point : 0 }}</h4>
          </template>
        </el-table-column>
        <!-- 所需點數 -->
        <el-table-column label="可兌換次數" width="130">
          <template slot-scope="scope">
            <h4>{{ scope.row.limit ? scope.row.num : '不限' }}</h4>
          </template>
        </el-table-column>
        <!-- 所需點數 -->
        <el-table-column label="等級限制">
          <template slot-scope="scope">
            <h4>{{ scope.row.level }}</h4>
          </template>
        </el-table-column>
        <!-- 所需點數 -->
        <el-table-column label="性別限制">
          <template slot-scope="scope">
            <h4>{{ scope.row.gender }}</h4>
          </template>
        </el-table-column>
        <!-- 所需點數 -->
        <el-table-column label="店櫃限制">
          <template slot-scope="scope">
            <h4 v-if="client">{{ scope.row.saleroom ? getName(scope.row.saleroom) : '全省' }}</h4>
          </template>
        </el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <!-- 複製 -->
            <el-button style="transform: translateY(5px);"
              type="info"
              icon="el-icon-copy-document" plain
              size="small" @click="copyItem(scope.row, scope.row.itemNo)">
            </el-button>
            <!-- 編輯 -->
            <el-button style="transform: translateY(5px);"
              type="primary"
              icon="el-icon-edit" plain
              size="small" @click="editItem(scope.row, scope.row.itemNo)">
            </el-button>
            <!-- 刪除 -->
            <el-button style="transform: translateY(5px);"
              type="danger"
              icon="el-icon-delete" plain
              size="small" @click="showDelDia(scope.row.itemNo)">
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 按鈕 -->
      <div style="margin-top: 10px;">
        <div style="text-align: right;">
          <el-button type="danger" @click="checkDelete" :disabled="!client || !type" icon="el-icon-delete">刪除分類</el-button>
          <el-button type="primary" @click="addItem" :disabled="!client || !type || itemLists.length >= 50" icon="el-icon-plus">新增品項</el-button>
          <el-button type="warning" @click="saveUpdate" :disabled="!client" icon="el-icon-download" style="margin-right: 0 !important;">儲存變更</el-button>
        </div>
      </div>
    </div>
    <!-- 編輯商品 -->
    <div v-if="openEdit" style="text-align: left;">
      <mu-appbar style="padding-left: 18px;" color="primary" title="商品內容">
        <mu-button slot="right" flat @click="cancel">取消</mu-button>
        <mu-button slot="right" flat @click="checkOk">確認</mu-button>
      </mu-appbar>
      <div style="margin-top: 0px; padding: 15px; overflow: auto; height: calc(100vh - 250px);">
        <el-row>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-row style="padding: 15px; height: 150px;">
              <el-col :span="10" style="position: relative;">
                <div class="img" :style="{ backgroundImage: defaultContent.url ? 'url(' + defaultContent.url + ')' : 'url(' + 'https://i.imgur.com/11eTf5t.jpg' + ')' }"></div>
              </el-col>
              <el-col :span="14" style="position: relative;">
                <mu-text-field full-width style="margin-bottom: 0;" :error-text="errorImg" v-model="defaultContent.url" label="圖片網址" label-float></mu-text-field>
                <mu-flex class="demo-linear-progress" v-if="showloading">
                  <mu-linear-progress></mu-linear-progress>
                </mu-flex>
                <div style="margin-top: 10px;">
                  <label style="padding: 7px 25px; border-radius: 4px; cursor: pointer; background-color: #999; color: #fff;">
                    <input style="display:none;" type="file" @change="onFileSelected"><mu-icon value="backup" style="transform: translateY(5px);"></mu-icon>
                  </label>
                </div>
              </el-col>
            </el-row>
            <!-- 所需點數 -->
            <el-row style="padding: 15px;">
              <div style="margin-bottom: 10px; font-weight: 400; font-size: 15px;">所需點數</div>
              <el-input v-model.number="defaultContent.point"></el-input>
              <div v-if="errorPoint" style="color: #f24;">{{ errorPoint }}</div>
            </el-row>
            <!-- 標題 -->
            <el-row style="padding: 15px;">
              <div style="margin-bottom: 10px; font-weight: 400; font-size: 15px;">商品名稱</div>
              <el-input v-model.trim="defaultContent.title"></el-input>
              <div v-if="errorName" style="color: #f24;">{{ errorName }}</div>
            </el-row>
            <!-- 標題 -->
            <el-row style="padding: 15px;">
              <div style="margin-bottom: 10px; font-weight: 400; font-size: 15px;">優惠案號</div>
              <el-input v-model.trim="defaultContent.itemNo"></el-input>
              <div v-if="errorItemNo" style="color: #f24;">{{ errorItemNo }}</div>
            </el-row>
            <!-- 內容 -->
            <el-row style="padding: 15px;">
              <div style="margin-bottom: 10px; font-weight: 400; font-size: 15px;">內容 (上限 150 字)</div>
              <el-input :rows="3" type="textarea" :maxlength="150" v-model="defaultContent.info"></el-input>
            </el-row>
            <!-- 注意事項 -->
            <el-row style="padding: 15px;">
              <div style="margin-bottom: 10px; font-weight: 700; font-size: 15px;">注意事項 (上限 700 字)</div>
              <el-input :rows="3" type="textarea" :maxlength="700" v-model="defaultContent.notice"></el-input>
            </el-row>
          </el-col>
          <!-- 第二列 -->
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <!-- 所需點數 -->
            <el-row style="padding: 15px;">
              <div style="margin-bottom: 10px; font-weight: 400; font-size: 15px;">數量限制
                <el-switch style="margin-left: 10px; transform: translateY(-1px);" v-model="defaultContent.limit"></el-switch>
              </div>
              <el-input style="margin-top: 10px;" v-if="defaultContent.limit" v-model.number="defaultContent.num" placeholder="可兌換次數"></el-input>
            </el-row>
            <!-- 所需等級 -->
            <el-row style="padding: 15px;">
              <div style="margin-bottom: 10px; font-weight: 400; font-size: 15px;">等級限制</div>
              <mu-select v-model="defaultContent.level" full-width>
                <mu-option v-for="option in levelLists" :key="option" :label="option" :value="option"></mu-option>
              </mu-select>
            </el-row>
            <!-- 性別限制 -->
            <el-row style="padding: 15px; margin-top: -20px;">
              <div style="margin-bottom: 10px; font-weight: 400; font-size: 15px;">性別限制</div>
              <mu-select v-model="defaultContent.gender" full-width>
                <mu-option v-for="option in genderList" :key="option" :label="option" :value="option"></mu-option>
              </mu-select>
            </el-row>
            <!-- 特定分眾 -->
            <el-row :gutter="20" style="padding: 15px; margin-top: -20px;" v-if="salerooms && salerooms[client]">
              <el-col :span="12">
                <div style="margin-bottom: 10px; font-weight: 400; font-size: 15px;">特定分眾</div>
                <mu-select v-model="defaultContent.selectedTag" full-width>
                  <mu-option v-for="option in tagLists" :key="option + 't'" :label="(option !== '') ? option : '無'" :value="option"></mu-option>
                </mu-select>
              </el-col>
              <el-col :span="12">
                <div style="margin-bottom: 10px; font-weight: 400; font-size: 15px;">特定生日月份</div>
                <mu-select v-model="defaultContent.month" full-width>
                  <mu-option v-for="month in months" :key="month + 'm'" :label="(month !== '') ? month : '無'" :value="month"></mu-option>
                </mu-select>
              </el-col>
            </el-row>
            <!-- 店櫃限制 -->
            <el-row style="padding: 15px; margin-top: -20px;" v-if="salerooms && salerooms[client]">
              <div style="margin-bottom: 10px; font-weight: 400; font-size: 15px;">店櫃限制</div>
              <mu-select v-model="defaultContent.saleroom" full-width>
                <mu-option v-for="option in salerooms[client]" :key="option.no" :label="option.name" :value="option.no"></mu-option>
              </mu-select>
            </el-row>
            <!-- 上下架時間 -->
            <el-row style="padding: 15px; margin-top: -20px; margin-bottom: 20px;" :gutter="10">
              <el-col :span="12">
                <div style="margin-bottom: 10px; font-weight: 400; font-size: 15px;">上架時間</div>
                <el-date-picker style="width: 100%"
                  @change="checkDate('on')"
                  v-model="defaultContent.onshelf"
                  type="date"
                  placeholder="選擇日期">
                </el-date-picker>
                <div v-if="errorOnshelf" style="color: #f24;">{{ errorOnshelf }}</div>
              </el-col>
              <el-col :span="12">
                <div style="margin-bottom: 10px; font-weight: 400; font-size: 15px;">下架時間</div>
                <el-date-picker style="width: 100%"
                  @change="checkDate('off')"
                  v-model="defaultContent.offshelf"
                  type="date"
                  placeholder="選擇日期">
                </el-date-picker>
                <div v-if="errorOffshelf" style="color: #f24;">{{ errorOffshelf }}</div>
              </el-col>
            </el-row>
            <!-- 可兌換門市 -->
            <el-row style="padding: 15px; margin-top: -20px;">
              <div style="margin-bottom: 10px; font-weight: 400; font-size: 15px;">是否顯示給非條件對象</div>
              <el-radio v-model="defaultContent.disabled" label="show">顯示但反灰</el-radio>
              <el-radio v-model="defaultContent.disabled" label="hide">不顯示</el-radio>
            </el-row>
          </el-col>
        </el-row>
      </div><hr>
    </div>
    <!-- 確認刪除 -->
    <mu-dialog :open.sync="deleteDia" title="移除品項" width="350">
      <div v-if="itemLists[deleteIdx]">確定要移除 <b>{{ itemLists[deleteIdx].title }}</b> 嗎？</div>
      <mu-button slot="actions" color="primary" @click="deleteItem">確認</mu-button>
    </mu-dialog>
    <!-- 增加 Type -->
    <mu-dialog :open.sync="addDia" title="增加分類" width="350">
      <mu-text-field v-model="typeName" :error-text="errorType" placeholder="請輸入分類名稱" style="width: 100%;"></mu-text-field><br/>
      <mu-button slot="actions" color="primary" @click="addType">確認</mu-button>
    </mu-dialog>
    <!-- 移除分類 -->
    <mu-dialog :open.sync="deleteTypeDia" title="移除分類" width="350">
      <div>確定要移除分類 <b>{{ type }}</b> 嗎？</div>
      <mu-button slot="actions" color="primary" @click="sendDeleteType">確認</mu-button>
    </mu-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import { SlickList, SlickItem } from 'vue-slicksort';
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      enviro: 'pro',
      itemLists: [],
      enviros: [
        { value: 'pro', name: '正式站' },
        { value: 'test', name: '測試站' }
      ],
      client: '',
      type: '',
      typeList: [],
      defaultContent: {},
      deleteDia: false,
      deleteTypeDia: false,
      deleteType: '',
      addDia: false,
      sortDia: false,
      typeName: '',
      deleteIdx: 0,
      openEdit: false,
      showloading: false,
      editIdx: 0,
      errorType: '',
      nowType: '',
      errorImg: '',
      errorPoint: '',
      errorName: '',
      errorItemNo: '',
      errorOnshelf: '',
      errorOffshelf: '',
      // 性別
      genderList: ['男', '女', '無'],
      // 分眾列表
      tagLists: [],
      // 等級列表
      levelLists: [],
      months: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', ''],
      // 櫃位
      salerooms: {
        '1655280896': [],
        '1654333455': []
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: '一周前',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }]
      },
    };
  },
  mounted() {
    if (this.clientList && this.clientList.length > 0) {
      // do something
    }
    else {
      this.getBrands();
    }
  },
  components: {
    SlickItem,
    SlickList
  },
  computed: {
    ...mapGetters({
      clientList: 'clients'
    }),
    showItem() {
      return this.itemLists.filter(el => el.type === this.type);
    },
    itemNoList() {
      return this.itemLists.map(el => el.itemNo);
    },
    itemNameList() {
      return this.itemLists.map(el => el.title);
    }
  },
  methods: {
    ...mapActions({
      getBrands: 'getBrands',
      updateItem: 'updateItem',
      getItemList: 'getItemList',
      getClientData: 'getClientData'
    }),
    sendDeleteType() {
      this.deleteTypeDia = false;
      const index = this.typeList.indexOf(this.deleteType);
      this.itemLists = this.itemLists.filter(el => el.type !== this.deleteType);
      this.typeList.splice(index, 1);
      this.type = this.typeList[0];
    },
    checkDelete() {
      this.deleteTypeDia = true;
      this.deleteType = this.type;
    },
    getName(no) {
      if (this.salerooms[this.client]) {
        const correspondSaleroom = this.salerooms[this.client].filter(el => el.no === no);
        if (correspondSaleroom && correspondSaleroom.length > 0) {
          return correspondSaleroom[0].name
        }
        else {
          return '全省';
        }
      }
      else {
        return '全省';
      }
    },
    addType() {
      if (this.typeName) {
        if (this.typeList.indexOf(this.typeName) === -1) {
          this.errorType = '';
          this.typeList.push(this.typeName);
          this.type = this.typeName;
          this.addDia = false;
        }
        else { this.errorType = '已有此分類' }
      }
      else { this.errorType = '請輸入分類名稱' }
    },
    checkDate(val) {
      const onShelf = this.defaultContent.onshelf;
      const offShelf = this.defaultContent.offshelf;

      if (val === 'on') {
        if (offShelf) {
          const offDate = new Date(offShelf);
          const onDate = new Date(onShelf);

          if (onDate > offDate) {
            this.defaultContent.offshelf = '';
          }
        }
      }
      else {
        if (onShelf) {
          const offDate = new Date(offShelf);
          const onDate = new Date(onShelf);

          if (onDate > offDate) {
            this.defaultContent.onshelf = '';
          }
        }
      }
    },
    saveUpdate() {
      const loading = this.$loading({ 
        lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)'
      });
      this.updateItem({ 
        clientId: this.client, enviro: this.enviro, 
        updateData: { type: this.typeList, list: this.itemLists } 
      })
      .then(() => {
        loading.close();
        this.$notify({ title: '更新成功', type: 'success' });
      })
    },
    onFileSelected(event) {
      this.showloading = true;
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('myfile', file);
      const config = {
        header: {
          "content-type": "multipart/form-data"
        }
      };
      // 上傳表單資料
      this.axios.post('https://ruentex-api-server.eilis-ai.com/api/upload', formData, config)
        .then((res) => {
          this.showloading = false;
          this.defaultContent.url = res.data.url;
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    cancel() {
      this.openEdit = false;
    },
    checkOk() {
      if (!this.defaultContent.point && this.defaultContent.point !== 0) { this.errorPoint = '請輸入所需點數' }
      else { this.errorPoint = '' }

      if (!this.defaultContent.url) { this.errorImg = '請輸入圖片網址' }
      else { this.errorImg = '' }

      if (!this.defaultContent.title) { this.errorName = '請輸入商品名稱' }
      else { this.errorName = '' }

      if (!this.defaultContent.itemNo) { this.errorItemNo = '請輸入優惠案號' }
      else { this.errorItemNo = '' }

      if (!this.defaultContent.onshelf) { this.errorOnshelf = '請選擇上架時間' }
      else { this.errorOnshelf = '' }

      if (!this.defaultContent.offshelf) { this.errorOffshelf = '請選擇下架時間' }
      else { this.errorOffshelf = '' }

      if (this.defaultContent.point !== '' && this.defaultContent.url && this.defaultContent.title && this.defaultContent.itemNo && this.defaultContent.onshelf && this.defaultContent.offshelf) {
        const checkNumber = (this.nowType === 'add') ? 1 : 2;
        if (this.itemNoList.filter(el => el === this.defaultContent.itemNo).length >= checkNumber) {
          this.errorItemNo = '此優惠案號已被使用';
        }
        else if (this.itemNameList.filter(el => el === this.defaultContent.title).length >= checkNumber) {
          this.errorName = '商品名稱已被使用';
        }
        else {
          this.openEdit = false;
          if (this.nowType === 'add') {
            this.itemLists.push(this.defaultContent);
          }
          if (this.nowType === 'edit') {
            this.itemLists[this.editIdx] = this.defaultContent;
          }
        }
      }
    },
    deleteItem() {
      this.deleteDia = false;
      this.itemLists.splice(this.deleteIdx, 1);
    },
    showDelDia(no) {
      this.deleteDia = true;
      this.deleteIdx = this.itemNoList.indexOf(no);
    },
    addItem() {
      this.nowType = 'add';
      this.openEdit = true;
      this.defaultContent = {
        url: '',
        itemNo: '',
        info: '',
        notice: '',
        gender: '無',
        selectedTag: '',
        limit: false,
        month: '',
        num: 1,
        onshelf: Date.now(),
        offshelf: '',
        title: '',
        point: 0,
        level: '無',
        disabled: 'show',
        saleroom: '無',
        type: this.type
      };
    },
    copyItem(row, no) {
      this.nowType = 'add';
      this.openEdit = true;
      this.defaultContent = JSON.parse(JSON.stringify(row));
    },
    editItem(row, no) {
      this.defaultContent = row;
      this.nowType = 'edit';
      this.openEdit = true;
      this.editIdx = this.itemNoList.indexOf(no);
    },
    getItem() {
      const loading = this.$loading({ lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)' });
      this.getItemList({ clientId: this.client, enviro: this.enviro }).then((res) => {
        if (res.itemData) {
          this.itemLists = res.itemData.list ? res.itemData.list : [];
          this.typeList = res.itemData.type ? res.itemData.type : [];
          this.type = this.typeList[0];
        }
        loading.close();
      })
      this.getClientData({ clientId: this.client, enviro: this.enviro }).then((_data) => {
        if (_data.status === '10020') {
          this.tagLists = (_data.result && _data.result.tags) ? Object.entries(_data.result.tags).filter(el => el[1]).map(st => st[0]).concat(['']) : [''];
          this.levelLists = _data.result.levels ? _data.result.levels : [];
          this.salerooms[this.client] = _data.result.salerooms ? _data.result.salerooms : [];
        }
      })
    },
    formatDate(date) {
      // const newDate = date ? new Date(date).toISOString() : '';
      // if (newDate) {
      //   return newDate.toString().substr(0, 10);
      // }
      // return '-';
      return new Date(date).toLocaleDateString();
    },
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return 'background-color: #eee; font-weight: 500; color: #444;'
      }
    },
  }
}
</script>

<style scoped>
  .typeBtn:hover {
    background-color: #f56c6c;
    color: #fff;
  }
  .img {
    width: 120px;
    height: 120px;
    background-size: cover;
    border: 1px solid #eee;
  }
  .active {
    display: inline-block;
    border: 1px solid #f56c6c;
    background-color: #f56c6c;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    border-radius: 5px;
    margin-right: 10px;
    padding: 6px 20px;
    transition: 0.3s;
  }
  .typeBtn {
    display: inline-block;
    border: 1px solid #f56c6c;
    color: #f56c6c;
    font-weight: 500;
    cursor: pointer;
    border-radius: 5px;
    margin-right: 10px;
    padding: 6px 20px;
    transition: 0.3s;
  }
</style>