<template>
  <div style="text-align: left; min-width: 900px;" v-loading.fullscreen.lock="pageLoading"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.7)">
    <div v-if="info" style="height: calc(100vh - 225px); overflow-y: auto; overflow-x: hidden;">
      <div>
        <el-radio-group v-model="tab">
          <el-radio-button v-for="(m, idx) in menus" :key="idx + 'm'" :label="m">
            {{ m }}
          </el-radio-button>
        </el-radio-group>
      </div>
      <!-- 題目設定 -->
      <div v-if="tab === '題目設定'">
        <el-row style="margin-top: 15px;">
          <el-col v-for="(col, idx) in formTitle"
            :span="col.span" :key="idx + 'c'">
            <div class="rowTitle">{{ col.label }}</div>
          </el-col>
        </el-row>
        <!-- 列表 -->
        <SlickList lockAxis="y" axis="y" v-model="info.questions" :lockToContainerEdges="true" :distance="10">
          <SlickItem v-for="(q, index) in info.questions" :index="index" :key="q.id" class="rowStyle">
            <el-row>
              <el-col :span="3" style="text-align: left;">
                <div class="rowContent">{{ index + 1 }}</div>
              </el-col>
              <el-col :span="3" style="text-align: left;">
                <div class="rowContent blue bold">{{ q.id }}</div>
              </el-col>
              <el-col :span="8" style="text-align: left;">
                <div class="rowContent">{{ q.title }}</div>
              </el-col>
              <el-col :span="4" style="text-align: left;">
                <div class="rowContent">{{ getTypeName(q.type) }}</div>
              </el-col>
              <el-col :span="6" style="text-align: left;">
                <div class="rowContent">
                  <el-button size="small" type="info" style="margin-bottom: 0 !important;"
                    icon="el-icon-copy-document"
                    plain
                    @click="editQues(q, 'copy')">
                  </el-button>
                  <el-button size="small" type="primary" style="margin-bottom: 0 !important;"
                    plain
                    @click="editQues(q, 'edit')">
                    編輯
                  </el-button>
                  <el-button size="small" type="danger" style="margin: 0 !important;"
                    plain
                    @click="deleteQues(index)">刪除
                  </el-button>
                </div>
              </el-col>
            </el-row>
          </SlickItem>
        </SlickList>
        <!-- 設定題目 -->
        <el-dialog :visible="editDia" width="600px" :show-close="false">
          <selectForm
            v-if="editDia"
            :nowSerial="formInfo.nowSerial"
            :allQuestion=info.questions
            :editQuesType="editQuesType"
            :editInfo="editInfo"
            @closeDia="closeDia"
            @saveQues="saveQues"
          />
        </el-dialog>
      </div>
      <!-- 通知訊息 -->
      <div v-if="tab === '通知訊息'" style="padding-top: 25px;">
        <div>
          <div class="title ty2">是否啟用完成問卷推播</div>
          <el-switch
            style="transform: scale(0.9); translateY(-0.5px);"
            v-model="info.isBroadcast"
            active-color="#13ce66"
            inactive-color="#ff4949">
          </el-switch>
        </div>
        <div style="margin-top: 20px;">
          <div class="title ty2">推播訊息類型</div>
          <el-radio v-model="info.msgType" label="message">文字</el-radio>
        </div>
        <div style="margin-top: 20px; width: 550px;">
          <div class="title">推播訊息內容</div>
          <el-input id="input2"
            type="textarea"
            :rows="3"
            placeholder="輸入文字"
            v-model="info.message"
            maxlength="100"
            show-word-limit
          />
          <!-- 參數 -->
          <div style="text-align: right; margin-top: 10px;">
            <el-button v-for="(btn, idx) in broadBtns"
              style="margin-right: 0 !important; margin-left: 10px !important; padding: 10px 15px;"
              @click="getIndex('input2', info.message, btn.text)"
              :key="idx + 'b'"
              size="small"
              type="info">
              {{ btn.label }}
            </el-button>
          </div>
        </div>
      </div>
      <!-- 問卷連結 -->
      <div v-if="tab === '問卷連結'" style="padding-top: 25px;">
        <div>
          <div class="title">Step1. 設定問卷 LIFF 連結</div>
          <el-input v-model="info.liffId" style="width: 450px;" placeholder="請輸入 LIFF ID"></el-input>
        </div><br>
        <div>
          <div class="title">Step2. 複製進入問卷快速連結</div>
          <div v-if="info.liffId" style="margin-top: 10px; background-color: #eee; padding: 10px; border-radius: 5px;">
            <span>https://liff.line.me/{{ info.liffId }}?formId={{ formInfo.id }}</span>
            <i class="el-icon-copy-document"
              style="margin-left: 10px; cursor: pointer;"
              @click="copyContent(`https://liff.line.me/${info.liffId}?formId=${formInfo.id}`)">
            </i>
          </div>
        </div>
      </div>
      <!-- 感謝頁設定 -->
      <div v-if="tab === '感謝頁設定'" style="padding-top: 25px;">
        <el-row :gutter="30">
          <el-col :span="12">
            <div>
              <div class="title">感謝頁封面圖</div>
              <el-row :gutter="20" style="margin-top: 5px; width: 450px;">
                <el-col :span="10">
                  <img :src="info.thanksCoverImg || defaultImg" width="100%"/>
                </el-col>
                <el-col :span="14">
                  <div style="height: 6px;"></div>
                  <uploadImg
                    :showText="true"
                    @finishUpload="finishUploadTwo"
                    ratio="800 * 500"
                    :size="1"
                  />
                </el-col>
              </el-row>
            </div>
            <div style="margin-top: 15px;">
              <el-input placeholder="輸入感謝頁標題文字"
                v-model="info.thanksTitle">
                <template slot="prepend">
                  感謝頁標題
                </template>
              </el-input>
            </div>
            <div style="margin-top: 15px;">
              <div class="title">感謝頁敘述</div>
              <el-input
                type="textarea"
                :rows="3"
                placeholder="輸入文字"
                v-model="info.thanksDesc"
                maxlength="100"
                show-word-limit
              />
            </div>
          </el-col>
          <el-col :span="12">
            <div>
              <div class="title ty2">感謝頁外連結按鈕</div>
              <el-switch
                style="transform: scale(0.9) translateY(-0.5px);"
                v-model="info.thanksLinkOpen"
                active-color="#13ce66"
                inactive-color="#ff4949">
              </el-switch>
            </div>
            <div v-if="info.thanksLinkOpen" style="margin-top: 10px;">
              <div>
                <el-input placeholder="輸入文字"
                  v-model="info.thanksBtnLabel">
                  <template slot="prepend">
                    按鈕文字
                  </template>
                </el-input>
              </div>
              <div style="margin-top: 15px;">
                <el-input placeholder="輸入網址連結"
                  v-model="info.thanksBtnLink">
                  <template slot="prepend">
                    按鈕連結
                  </template>
                </el-input>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 基本資料 -->
      <div v-if="tab === '基本資料'" style="padding-top: 25px;">
        <el-row :gutter="30">
          <el-col :span="12">
            <el-input placeholder="輸入問卷標題文字"
              v-model="info.title">
              <template slot="prepend">
                問卷標題
                <span style="color: #f24;"> *</span>
              </template>
            </el-input>
            <el-input placeholder="輸入問卷編號"
              style="margin: 15px 0;"
              v-model.trim="info.id"
              :disabled="editType === 'edit'">
              <template slot="prepend">
                問卷編號
                <span style="color: #f24;"> *</span>
              </template>
            </el-input>
            <el-button @click="autoId" :disabled="editType === 'edit'">
              隨機產生問卷編號
            </el-button>
            <div style="margin-top: 15px;">
              <div class="title ty2">完成問卷獎勵</div>
              <el-radio-group v-model="info.award">
                <el-radio label="1">無</el-radio>
                <el-radio label="2">點數</el-radio>
                <el-radio label="3">優惠券</el-radio>
              </el-radio-group>
            </div>
            <div id="award" style="margin-top: 5px;">
              <div v-if="info.award === '2'">
                <el-input-number v-model="info.awardPoint" :min="1"></el-input-number>
              </div>
              <div v-if="info.award === '3'">
                <el-select v-model="info.awardCoupon" placeholder="選擇優惠券">
                  <el-option
                    v-for="item in itemList"
                    :key="item.itemNo"
                    :label="item.title"
                    :value="item.itemNo">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div style="margin-top: 15px;">
              <div class="title">歡迎封面圖</div>
              <el-row :gutter="20" style="margin-top: 5px; width: 450px;">
                <el-col :span="10">
                  <img :src="info.coverImg || defaultImg" width="100%"/>
                </el-col>
                <el-col :span="14">
                  <div style="height: 6px;"></div>
                  <uploadImg
                    :showText="true"
                    @finishUpload="finishUpload"
                    ratio="800 * 500"
                    :size="1"
                  />
                </el-col>
              </el-row>
            </div>
            <div style="margin-top: 15px;">
              <div class="title">歡迎文字</div>
              <el-input
                type="textarea"
                :rows="3"
                placeholder="輸入文字"
                v-model="info.welcomeText"
                maxlength="100"
                show-word-limit
              />
            </div>
          </el-col>
          <el-col :span="12">
            <div class="title">問卷條件限制</div>
            <div>
              <span>總填寫次數（每人）</span>
              <el-switch
                style="transform: scale(0.9); translateY(-0.5px);"
                v-model="info.userLimitOpen"
                active-color="#13ce66"
                inactive-color="#ff4949">
              </el-switch>
              <el-input-number v-if="info.userLimitOpen"
                v-model="info.userLimit"
                style="margin-left: 15px; width: 140px;"
                size="small"
                :min="1">
              </el-input-number>
            </div>
            <div style="margin-top: 10px;">
              <span style="margin-right: 15px;">問卷開放時間限制</span>
              <el-switch
                style="transform: scale(0.9) translateY(-0.5px);"
                v-model="info.durationLimit"
                active-color="#13ce66"
                inactive-color="#ff4949">
              </el-switch>
              <div style="margin-top: 10px;" v-if="info.durationLimit">
                <el-date-picker
                  style="width: 400px;"
                  v-model="value1"
                  type="daterange"
                  range-separator="-"
                  start-placeholder="開始日期"
                  end-placeholder="結束日期">
                </el-date-picker>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div><hr>
    <div>
      <el-row>
        <el-col :span="12">
          <el-button type="info"
            icon="el-icon-arrow-left"
            @click="goBack">
            返回
          </el-button>
        </el-col>
        <el-col :span="12">
          <div style="text-align: right;">
            <el-button icon="el-icon-plus"
              v-if="tab === '題目設定'"
              @click="editQues(defaultQues, 'add')"
              style="margin-botton: 0 !important;">
              新增題目
            </el-button>
            <el-button type="warning"
              @click="saveChange"
              style="margin: 0 !important;">
              儲存設定
            </el-button>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
// import { mapGetters, mapActions } from 'vuex';
import { SlickList, SlickItem } from 'vue-slicksort';
import { generateCode }         from '@/config/data';
import defaultImg               from '@/assets/default-image.png';
import selectForm               from './selectForm.vue';
import uploadImg                from './uploadImg.vue';


export default {
  data() {
    return {
      pageLoading: false,
      info: null,
      defaultImg: defaultImg,
      value1: '',
      tab: '基本資料',
      menus: ['基本資料', '題目設定', '感謝頁設定', '問卷連結', '通知訊息'],
      editDia: false,
      editInfo: null,
      formTitle: [
        { label: '順序', span: 3 },
        { label: '題目編號', span: 3 },
        { label: '題目標題', span: 8 },
        { label: '題目類型', span: 4 },
        { label: '操作', span: 6 }
      ],
      broadBtns: [
        { label: '會員姓名', text: 'ELS_NAME' },
        { label: '問卷標題', text: 'FORM_NAME' },
        { label: '問卷獎勵獲得點數', text: 'AWARD_POINT' },
        { label: '問卷獎勵獲得優惠券名稱', text: 'AWARD_COUPON' },
      ],
      // 預設題目
      defaultQues: {
        desc: '',
        title: '',
        type: '1',
        id: '',
        img: '',
        options: [],
        required: false
      },
      editQuesType: 'add'
    }
  },
  props: ['clientId', 'formInfo', 'editType', 'itemList'],
  mounted() {
    this.info = this.formInfo;
    this.value1 = this.info.duration;
  },
  components: {
    SlickList,
    SlickItem,
    selectForm,
    uploadImg
  },
  methods: {
    showNotify(title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type
      });
    },
    goBack() {
      this.$emit('goBack', 1);
    },
    autoId() {
      this.info.id = generateCode(8);
    },
    finishUploadTwo(url) {
      this.info.thanksCoverImg = url;
    },
    finishUpload(url) {
      this.info.coverImg = url;
    },
    closeDia() {
      this.editDia = false;
    },
    saveQues(ques) {
      const quesId = ques.id;
      let isInList = false;
      for (let i = 0; i < this.info.questions.length; i += 1) {
        if (this.info.questions[i].id === quesId) {
          isInList = true;
          this.info.questions[i] = ques;
          break;
        }
      }
      if (!isInList) {
        this.info.questions.push(ques);
        this.info.nowSerial = quesId;
      }
      this.editDia = false;
    },
    editQues(ques, type) {
      this.editInfo = JSON.parse(JSON.stringify(ques));
      if (type !== 'edit') {
        // 計算流水號
        this.editInfo.id = '';
      }
      this.editQuesType = type;
      this.editDia = true;
    },
    deleteQues(idx) {
      this.info.questions.splice(idx, 1);
    },
    getIndex(ref, text, inputText) {
      const startPos = document.getElementById(ref).selectionStart;
      const endPos = document.getElementById(ref).selectionEnd;
      const result = text.substring(0, startPos) + inputText + text.substring(endPos);
      
      this.info.message = result;
    },
    getTypeName(type) {
      let name = '';
      switch (type) {
        case '1':
          name = '單選題';
          break;
        case '2':
          name = '複選題';
          break;
        case '3':
          name = '多行文字'
          break;
        case '4':
          name = '分類標題';
        default:
          break;
      }
      return name
    },
    checkResult() {
      const specialCharacter = /^[a-zA-Z0-9]+$/g;
      let allow = true;
      // 檢查標題
      if (!this.info.title) {
        allow = false;
        this.showNotify('', '請輸入問卷標題', 'warning');
      }
      // 檢查編號
      else if (!this.info.id) {
        allow = false;
        this.showNotify('', '請輸入問卷編號', 'warning');
      }
      // 檢查編號格式
      else if (!specialCharacter.test(this.info.id)) {
        allow = false;
        this.showNotify('', '問卷編號僅接受大小寫英文+數字', 'warning');
      }
      // 感謝頁
      else if (this.info.thanksLinkOpen) {
        if (!this.info.thanksBtnLabel) {
          allow = false;
          this.showNotify('', '請輸入感謝頁按鈕文字', 'warning');
        }
        else if (!this.info.thanksBtnLink) {
          allow = false;
          this.showNotify('', '請輸入感謝頁按鈕連結', 'warning');
        }
        else if (!this.info.thanksBtnLink.startsWith('https')) {
          allow = false;
          this.showNotify('', '網址必須為 https:// 開頭', 'warning');
        }
      }

      return allow;
    },
    saveChange() {
      if (this.checkResult()) {
        if (this.value1) {
          this.info.duration[0] = new Date(this.value1[0]).getTime();
          this.info.duration[1] = new Date(this.value1[1]).getTime();
        }
        this.$emit('saveForm', this.info);
      }
    }
  }
}

</script>

<style scoped>

.ty2 {
  display: inline-block;
  margin-right: 15px;
}

.title {
  font-weight: 500;
  margin-bottom: 5px;
}

.blue {
  color: #2b88e6;
}

.bold {
  font-weight: 500;
}

.rowStyle {
  border: 1px solid #eee;
  background-color: #fff;
  cursor: pointer;
  overflow: hidden;
}

.rowTitle {
  text-align: left;
  padding: 10px 15px;
  font-size: 14px;
  background-color: #eee;
  color: #777;
  font-weight: 600;
}

.rowContent {
  padding: 10px 14px;
  line-height: 30px;
  font-size: 14px;
}

</style>