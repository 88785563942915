import Vue    from 'vue'
import Router from 'vue-router'

import login             from './views/login.vue';
import mainPage          from './views/mainPage.vue';
import changeLog         from './views/changeLog.vue';
import itemList          from './views/itemList.vue';
import manageCoupon      from './views/manageCoupon.vue';
import queryMember       from './views/queryMember.vue';
import sendPoint         from './views/sendPoint.vue';
import transactionRecord from './views/transactionRecord.vue';
import exchangeRecord    from './views/exchangeRecord.vue';
import tag               from './views/tag.vue';
import salerooms         from './views/salerooms.vue';
import addMember         from './views/addMember.vue';
import lottery           from './views/lottery.vue';
import develop           from './views/develop.vue';
import form              from './views/form.vue';
import character         from './views/character.vue';
import settingGDS        from './views/settingGDS.vue';
import whittardGA        from './views/whittardGA.vue';


Vue.use(Router)


export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      component: login,
    },
    {
      path: '/',
      component: mainPage,
      children: [
        {
          path: '',
          component: changeLog
        },
        {
          path: 'changeLog',
          component: changeLog
        },
        {
          path: 'itemList',
          component: itemList
        },
        {
          path: 'form',
          component: form
        },
        {
          path: 'manageCoupon',
          component: manageCoupon
        },
        {
          path: 'queryMember',
          component: queryMember
        },
        {
          path: 'lottery',
          component: lottery
        },
        {
          path: 'sendPoint',
          component: sendPoint
        },
        {
          path: 'transactionRecord',
          component: transactionRecord
        },
        {
          path: 'exchangeRecord',
          component: exchangeRecord
        },
        {
          path: 'tag',
          component: tag
        },
        {
          path: 'salerooms',
          component: salerooms
        },
        {
          path: 'addMember',
          component: addMember
        },
        {
          path: 'develop',
          component: develop
        },
        {
          path: 'character',
          component: character
        },
        {
          path: 'settingGDS',
          component: settingGDS
        },
        {
          path: 'chart/:id',
          component: whittardGA
        }
      ]
    }
  ]
})
