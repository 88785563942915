import Vue   from 'vue';
import Vuex  from 'vuex';
import axios from 'axios';
import CryptoJS from 'crypto-js';

Vue.use(Vuex);
/* eslint-disable */

const origin_url   = 'ruentex-api-server.eilis-ai.com/api';
const test_api_url = 'ruentex-api-server.eilis-ai.com/api/test';
const pro_api_url  = 'ruentex-api-server.eilis-ai.com/api/v1';
const ruentex_url  = 'etrade-ruentex.eilis-ai.app/v1';

const apikey = '175e0506314052a7d2a45ae20f6b0d257720b5f5';
const key    = 'XHOJaT4QqkvlsmjfuLhPlOInD9NiVCv1';
const iv     = 'N6lRbzhhbyHgiU66';

const keyStr = CryptoJS.enc.Utf8.parse(key);
const ivStr  = CryptoJS.enc.Utf8.parse(iv);

const options = {
    iv: ivStr,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
};


const state = {
    clients: [],
    gaList: []
}


const getters = {
    clients: state => state.clients,
    gaList: state => state.gaList,
}


const mutations = {
    RECEIVE_CLIENT(state, payload) {
        // const testClient = [{
        //     clientId: '12345',
        //     name: '測試品牌',
        //     ratio: 50,
        //     title: 'TS'
        // }];
        // state.clients = payload.concat(testClient);
        state.clients = payload;
    },
    RECEIVE_GA(state, payload) {
        state.gaList = payload;
    }
}


function sendRequest(payload, apiUrl) {
    const signature = CryptoJS.AES.encrypt(JSON.stringify(payload), keyStr, options).toString();
    const data = {
        content: signature
    };
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'api-key': apikey,
        }
    };
    return axios.post(apiUrl, data, config)
        .then((res) => {
            return Promise.resolve(res.data);
        })
}


const actions = {
    checkPassword({ }, payload) {
        const apiUrl = `https://${origin_url}/checkPassword`;
        return axios.post(apiUrl, payload).then((res) => {
            return res.data.Message;
        })
    },
    changePassword({ }, payload) {
        const apiUrl = `https://${origin_url}/setPassword`;
        return axios.post(apiUrl, payload).then((res) => {
            return res.data.Message;
        })
    },
    sendPoint({ }, payload) {
        const apiUrl = (payload.enviro === 'pro') ? `https://${pro_api_url}/sendPoint` : `https://${test_api_url}/sendPoint`;
        return sendRequest(payload, apiUrl).then((res) => {
            return res;
        })
    },
    getClientData({ }, payload) {
        const apiUrl = (payload.enviro === 'pro') ? `https://${pro_api_url}/getClientData` : `https://${test_api_url}/getClientData`;
        return axios.post(apiUrl, payload)
            .then((res) => {
                return res.data;
            })
    },
    queryRuentexMember({ }, payload) {
        const apiUrl = `https://${ruentex_url}/readMember`;
        return sendRequest(payload, apiUrl).then((res) => {
            return res;
        })
    },
    // 取得品項
    getItemList({ }, payload) {
        const apiUrl = (payload.enviro === 'pro') ? `https://${pro_api_url}/getItemList` : `https://${test_api_url}/getItemList`;
        return sendRequest(payload, apiUrl).then((res) => {
            return res;
        })
    },
    // 取得品項
    sendPointsInStream({ }, payload) {
        const apiUrl = (payload.enviro === 'pro') ? `https://${pro_api_url}/sendPointsInStream` : `https://${test_api_url}/sendPointsInStream`;
        return sendRequest(payload, apiUrl).then((res) => {
            return res;
        })
    },
    fixedTagNum({ }, payload) {
        const apiUrl = `https://${origin_url}/fixedTagNum`;
        return sendRequest(payload, apiUrl).then((res) => {
            return res;
        })
    },
    updateItem({ }, payload) {
        const apiUrl = (payload.enviro === 'pro') ? `https://${pro_api_url}/updateItem` : `https://${test_api_url}/updateItem`;
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'api-key': apikey,
            }
        };
        return axios.post(apiUrl, payload, config).then((res) => {
            return res;
        })
    },
    getCodeList({ }, payload) {
        const apiUrl = (payload.enviro === 'pro') ? `https://${pro_api_url}/getCodeList` : `https://${test_api_url}/getCodeList`;
        return sendRequest(payload, apiUrl).then((res) => {
            return res;
        })
    },
    deleteCode({ }, payload) {
        const apiUrl = (payload.enviro === 'pro') ? `https://${pro_api_url}/deleteCode` : `https://${test_api_url}/deleteCode`;
        return sendRequest(payload, apiUrl).then((res) => {
            return res;
        })
    },
    deleteUser({ }, payload) {
        const apiUrl = (payload.enviro === 'pro') ? `https://${pro_api_url}/deleteUser` : `https://${test_api_url}/deleteUser`;
        return sendRequest(payload, apiUrl).then((res) => {
            return res;
        })
    },
    deleteUserFromBigquery({ }, payload) {
        const apiUrl = `https://${pro_api_url}/deleteUserFromBigquery`;
        return sendRequest(payload, apiUrl).then((res) => {
            return res;
        })
    },
    getTransactionRecord({ }, payload) {
        const apiUrl = (payload.enviro === 'pro') ? `https://${pro_api_url}/getTransactionRecord` : `https://${test_api_url}/getTransactionRecord`;
        return sendRequest(payload, apiUrl).then((res) => {
            return res;
        })
    },
    getExchangeRecord({ }, payload) {
        const apiUrl = (payload.enviro === 'pro') ? `https://${pro_api_url}/getExchangeRecord` : `https://${test_api_url}/getExchangeRecord`;
        return sendRequest(payload, apiUrl).then((res) => {
            return res;
        })
    },
    updateMemberData({ }, payload) {
        const apiUrl = (payload.enviro === 'pro') ? `https://${pro_api_url}/updateMemberData` : `https://${test_api_url}/updateMemberData`;
        return sendRequest(payload, apiUrl).then((res) => {
            return res;
        })
    },
    generateCoupon({ }, payload) {
        const apiUrl = (payload.enviro === 'pro') ? `https://etrade-ruentex.eilis-ai.com/v1/generateCoupon` : `https://etrade-ruentex.eilis-ai.com/test/generateCoupon`;
        return sendRequest(payload, apiUrl).then((res) => {
            return res;
        })
    },
    getUserByPhone({ }, payload) {
        const apiUrl = (payload.enviro === 'pro') ? `https://${pro_api_url}/getUserByPhone` : `https://${test_api_url}/getUserByPhone`;
        return sendRequest(payload, apiUrl).then((res) => {
            return res;
        })
    },
    register({ }, payload) {
        const apiUrl = `https://${pro_api_url}/register`;
        return sendRequest(payload, apiUrl).then((res) => {
            return res;
        })
    },
    getForms({ }, payload) {
        const apiUrl = `https://${pro_api_url}/getForms`;
        return sendRequest(payload, apiUrl).then((res) => {
            return res;
        })
    },
    updateForm({ }, payload) {
        const apiUrl = `https://${pro_api_url}/updateForm`;
        return sendRequest(payload, apiUrl).then((res) => {
            return res;
        })
    },
    removeForm({ }, payload) {
        const apiUrl = `https://${pro_api_url}/removeForm`;
        return sendRequest(payload, apiUrl).then((res) => {
            return res;
        })
    },
    setLottery({ }, payload) {
        const apiUrl = `https://${pro_api_url}/setLottery`;
        return sendRequest(payload, apiUrl).then((res) => {
            return res;
        })
    },
    deleteLottery({ }, payload) {
        const apiUrl = `https://${pro_api_url}/deleteLottery`;
        return sendRequest(payload, apiUrl).then((res) => {
            return res;
        })
    },
    getLotteries({ }, payload) {
        const apiUrl = `https://${pro_api_url}/getLotteries`;
        return sendRequest(payload, apiUrl).then((res) => {
            return res;
        })
    },
    updateUser({ }, payload) {
        const apiUrl = `https://${pro_api_url}/updateUser`;
        return sendRequest(payload, apiUrl).then((res) => {
            return res;
        })
    },
    getTagList({ }, payload) {
        const apiUrl = (payload.enviro === 'pro') ? `https://${pro_api_url}/getTagList` : `https://${test_api_url}/getTagList`;
        return sendRequest(payload, apiUrl).then((res) => {
            return res;
        })
    },
    getTagUser({ }, payload) {
        const apiUrl = (payload.enviro === 'pro') ? `https://${pro_api_url}/getTagUser` : `https://${test_api_url}/getTagUser`;
        return sendRequest(payload, apiUrl).then((res) => {
            return res;
        })
    },
    getUserTag({ }, payload) {
        const apiUrl = (payload.enviro === 'pro') ? `https://${pro_api_url}/getUserTag` : `https://${test_api_url}/getUserTag`;
        return sendRequest(payload, apiUrl).then((res) => {
            return res;
        })
    },
    addUserToTag({ }, payload) {
        const apiUrl = (payload.enviro === 'pro') ? `https://${pro_api_url}/addUserToTag` : `https://${test_api_url}/addUserToTag`;
        return sendRequest(payload, apiUrl).then((res) => {
            return res;
        })
    },
    streamTags({ }, payload) {
        const apiUrl = (payload.enviro === 'pro') ? `https://${pro_api_url}/streamTag` : `https://${test_api_url}/streamTag`;
        return sendRequest(payload, apiUrl).then((res) => {
            return res;
        })
    },
    removeUserTag({ }, payload) {
        const apiUrl = (payload.enviro === 'pro') ? `https://${pro_api_url}/removeUserTag` : `https://${test_api_url}/removeUserTag`;
        return sendRequest(payload, apiUrl).then((res) => {
            return res;
        })
    },
    deleteTag({ }, payload) {
        const apiUrl = (payload.enviro === 'pro') ? `https://${pro_api_url}/deleteTag` : `https://${test_api_url}/deleteTag`;
        return sendRequest(payload, apiUrl).then((res) => {
            return res;
        })
    },
    addPageTag({ }, payload) {
        const apiUrl = (payload.enviro === 'pro') ? `https://${pro_api_url}/addPageTag` : `https://${test_api_url}/addPageTag`;
        return sendRequest(payload, apiUrl).then((res) => {
            return res;
        })
    },
    setSaleroom({ }, payload) {
        const apiUrl = (payload.enviro === 'pro') ? `https://${pro_api_url}/setSaleroom` : `https://${test_api_url}/setSaleroom`;
        return sendRequest(payload, apiUrl).then((res) => {
            return res;
        })
    },
    getBrands({ state, commit }, payload) {
        // 確定人員權限
        const loginData = JSON.parse(localStorage.getItem('loginData'));
        const character = loginData.character

        const apiUrl = `https://${pro_api_url}/getBrands`;
        return axios.get(apiUrl).then((res) => {
            let brandList = [];
            if (character === '管理員') {
                brandList = res.data.result
            }
            else if (character === 'A區編輯' || character === 'A區客服') {
                brandList = res.data.result.filter(el => el.clientId !== '1656289509')
            }
            else {
                brandList = res.data.result.filter(el => el.clientId === '1656289509')
            }
            commit('RECEIVE_CLIENT', brandList);
            return res.data;
        })
    },
    login({ }, payload) {
        const apiUrl = `https://${origin_url}/login`;
        return axios.post(apiUrl, payload).then((res) => {
            return res.data;
        })
    },
    getAllCharacters({ }, payload) {
        const apiUrl = `https://${origin_url}/getAllCharacters`;
        return axios.get(apiUrl).then((res) => {
            return res.data;
        })
    },
    setCharacter({ }, payload) {
        const apiUrl = `https://${origin_url}/setCharacter`;
        return axios.post(apiUrl, payload).then((res) => {
            return res.data;
        })
    },
    removeCharacter({ }, payload) {
        const apiUrl = `https://${origin_url}/removeCharacter`;
        return axios.post(apiUrl, payload).then((res) => {
            return res.data;
        })
    },
    getSheetName({ }, payload) {
        const apiUrl = (payload.enviro === 'pro') ? `https://${pro_api_url}/getSheetName` : `https://${test_api_url}/getSheetName`;
        return sendRequest(payload, apiUrl).then((res) => {
            return res;
        })
    },
    enableClient({ }, payload) {
        const apiUrl = (payload.enviro === 'pro') ? `https://${pro_api_url}/enableClient` : `https://${test_api_url}/enableClient`;
        return sendRequest(payload, apiUrl).then((res) => {
            return res;
        })
    },
    addTable({ }, payload) {
        const apiUrl = `https://${pro_api_url}/addTable`;
        return axios.post(apiUrl, payload).then((res) => {
            return res.data;
        })
    },
    deleteTable({ }, payload) {
        const apiUrl = `https://${pro_api_url}/deleteTable`;
        return axios.post(apiUrl, payload).then((res) => {
            return res.data;
        })
    },
    getIframe({ state, commit }, payload) {
        const apiUrl = `https://${pro_api_url}/getIframe`;
        return axios.get(apiUrl, payload).then((res) => {
            let emptyList = [];
            const brandList = Object.keys(res.data);
            for (let i = 0; i < brandList.length; i += 1) {
                const brand = brandList[i];
                let info = res.data[brand] || {};
                info.clientId = brand;
                
                emptyList.push(info);
            }
            commit('RECEIVE_GA', Object.values(res.data));
            return res.data;
        })
    },
    setIframe({ }, payload) {
        const apiUrl = `https://${pro_api_url}/setIframe`;
        return axios.post(apiUrl, payload).then((res) => {
            return res.data;
        })
    },
}


export default {
    state,
    getters,
    mutations,
    actions
}