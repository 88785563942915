<template>
  <div style="padding: 20px;" v-loading.fullscreen.lock="pageLoading"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.7)">
    <div style="text-align: left;">
      <el-select style="margin-bottom: 10px;"
        v-model="client"
        placeholder="請選擇品牌"
        @change="getIframes">
        <el-option v-for="item in clientList"
          :key="item.clientId"
          :label="item.name"
          :value="item.clientId">
        </el-option>
      </el-select>
      <el-table style="border: 1px solid #eee; padding: 0;" key="list" :data="tableData" empty-text="暫無設定內嵌報表">
        <el-table-column prop="name"
          width="180"
          label="分頁名稱">
          <template slot-scope="scope">
            <el-input size="small" v-model="scope.row.name"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="url"
          label="內嵌報表連結">
          <template slot-scope="scope">
            <el-input size="small" v-model="scope.row.url"></el-input>
          </template>
        </el-table-column>
        <el-table-column width="80">
          <template slot-scope="scope">
            <el-button type="danger" size="small" style="margin: 0 !important;" @click="deleteSheet(scope.$index)">刪除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="text-align: right; margin-top: 10px;">
      <el-button type="primary" @click="addSheet">新增報表</el-button>
      <el-button :disabled="checkDisable()" type="warning" style="margin-right: 0 !important;" @click="saveChange">儲存變更</el-button>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      pageLoading: false,
      client: '',
      tableData: []
    };
  },
  computed: {
    ...mapGetters({
      list: 'gaList',
      clientList: 'clients'
    })
  },
  methods: {
    ...mapActions({
      setIframe: 'setIframe'
    }),
    addSheet() {
      this.tableData.push({
        name: '', url: ''
      })
    },
    deleteSheet(index) {
      this.tableData.splice(index, 1);
    },
    checkDisable() {
      const emptyNameList = this.tableData.filter(el => !el.name);
      const emptyUrlList = this.tableData.filter(el => !el.url);
      if (emptyNameList.length > 0 || emptyUrlList.length > 0) {
        return true;
      }
      return false;
    },
    getIframes(val) {
      const corresPondList = this.list.filter(el => el.clientId === val)[0];
      this.tableData = (corresPondList && corresPondList.list) ? corresPondList.list : [];
    },
    saveChange() {
      this.pageLoading = true;
      const nowBrand = this.clientList.filter(el => el.clientId === this.client)[0].name;
      this.setIframe({ clientId: this.client, list: this.tableData, brand: nowBrand }).then(() => {
        this.pageLoading = false;
        this.getIframes(this.client);
        this.$notify({
          title: '已儲存變更',
          type: 'success'
        });
      })
    }
  }
}
</script>

<style scoped>

</style>