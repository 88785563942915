<template>
  <div style="text-align: left; position: relative;">
    <iframe width="100%" :src="iframeLink"
      frameborder="0" style="border: 0; height: calc(100vh - 80px)" allowfullscreen>
    </iframe>
    <div style="width: 100%; background-color: #fff; font-weight: 500; position: absolute; right: 0px; top: calc(100vh - 110px); z-index: 5000; padding-top: 10px; padding-bottom: 10px; padding-right: 20px; text-align: right;">
      輸入 ctrl + shift + E 刷新資料
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters({
      gaList: 'gaList'
    }),
    iframeLink() {
      if (this.gaList.length > 0) {
        const brandIdx  = parseInt(this.$route.params.id[2]) - 1;
        const sheetIdx  = parseInt(this.$route.params.id[4]) - 1;
        return this.gaList[brandIdx].list[sheetIdx].url;
      }
      return '';
    }
  },
  methods: {
    ...mapActions({
    }),
  },
}
</script>

<style scoped>
</style>