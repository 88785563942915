<template>
  <div class="background" v-loading.fullscreen.lock="pageLoading"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.7)">
    <!-- 會員登入 -->
    <div class="login">
      <h2>潤泰管理後台</h2>
      <hr style="width: 90%; border: 1px solid #eee; margin: 0 auto;"/>
      <div style="padding: 30px 20px;">
        <div style="text-align: left;">人員編號</div>
        <mu-text-field style="width: 100%;" v-model.trim="id"></mu-text-field>
        <div style="text-align: left; margin-top: 15px;">密碼</div>
        <mu-text-field style="width: 100%; margin-bottom: 5px;" v-model.trim="password" :action-icon="visibility ? 'visibility' : 'visibility_off'" :action-click="() => (visibility = !visibility)" :type="visibility ? 'text' : 'password'"></mu-text-field>
        <div style="color: #f24">{{ errorText }}</div>
      </div>
      <div style="position: relative; padding: 0 20px;">
        <el-button @click="sendLogin" type="primary" style="width: 100%; bottom: 0;">登入</el-button>
      </div>
    </div>
    <div style="position: fixed; bottom: 10px; right: 10px; text-align: right;">
      <img src="logo_white.png" width="120"/>
      <div style="padding-left: 8px; font-size: 13px; color: #fff;">Copyright © 2020 埃立思科技股份有限公司 版權所有</div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      pageLoading: false,
      visibility: false,
      id: '',
      password: '',
      errorText: ''
    };
  },
  methods: {
    ...mapActions({
      login: 'login'
    }),
    sendLogin() {
      this.pageLoading = true;
      const postData = {
        id: this.id,
        password: this.password
      };
      this.login(postData).then((res) => {
        this.pageLoading = false;
        if (res.Message !== '成功') {
          this.errorText = res.Message;
        }
        else {
          this.errorText = '';
          localStorage.setItem('loginData', JSON.stringify(res.result));
          this.$router.push('/');
        }
      })
    }
  }
}
</script>

<style scoped>
.background {
  background: 100% linear-gradient(to left, rgb(0,128,129),rgb(0,49,82));
  padding: 20px;
  min-height: 800px;
  height: 100vh;
}
.link {
  color: #0a82f2;
  text-decoration: underline;
  cursor: pointer;
}
.login {
  width: 90%;
  max-width: 400px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 8px;
  top: 20vh;
  background-color: #fff;
  display: inline-block;
  padding: 10px 10px;
}
</style>