<template>
  <div>
    <label class="imgBtn">
      <input style="display: none;" type="file" @change="onFileSelected">
      <span>上傳圖片</span>
    </label>
    <div v-if="showText" style="font-size: 13px;">
      <div style="margin-top: 15px;">檔案格式：png/jpeg</div>
      <div>建議尺寸：{{ ratio }}</div>
      <div>檔案大小上限：{{ size }}</div>
    </div>
    <mu-dialog :open="uploadLoading" width="400">
      <div style="margin-bottom: 5px;">上傳圖片中...</div>
      <mu-linear-progress></mu-linear-progress>
    </mu-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      uploadLoading: false,
      url: 'https://ruentex-api-server.eilis-ai.com/api/upload'
    }
  },
  props: ['ratio', 'size', 'showText'],
  methods: {
    showNotify(title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type
      });
    },
    onFileSelected(event) {
      const file = event.target.files[0];
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
      const isLt1M = file.size / 1024 / 1024 <= this.size;
      // 檔案類型
      if (!isJPG) {
        this.showNotify('', '不支援非圖片類型檔案', 'warning');
        return;
      }
      if (!isLt1M) {
        this.showNotify('', '檔案大小不符合', 'warning');
        return;
      }
      this.uploadLoading = true;
      const formData = new FormData();
      formData.append('myfile', file);
      const config = {
        header: {
          "content-type": "multipart/form-data"
        }
      };
      this.axios.post(this.url, formData, config)
        .then((res) => {
          this.uploadLoading = false;
          this.$emit('finishUpload', res.data.url);
        })
    }
  }
}
</script>

<style>

.imgBtn {
  padding: 6px 20px;
  border-radius: 4px;
  font-size: 13px;
  border: 1px solid #777;
  cursor: pointer;
  font-weight: 500;
  background-color: fff;
  color: #777;
}

</style>