<template>
  <div style="padding: 20px" v-loading.fullscreen.lock="pageLoading"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.7)">
    <el-row :gutter="10" style="max-width: 1000px;">
      <!-- 選擇環境 -->
      <el-col :span="8">
        <el-select style="margin-bottom: 10px; width: 100%;" v-model="enviro" placeholder="請選擇環境" @change="clearData">
          <el-option v-for="item in enviros" :key="item.value" :label="item.name" :value="item.value"></el-option>
        </el-select>
      </el-col>
      <!-- 選擇品牌 -->
      <el-col :span="8">
        <el-select style="margin-bottom: 10px; width: 100%;" v-model="client" placeholder="請選擇品牌" @change="getSaleroom">
          <el-option v-for="item in clientList" :key="item.clientId" :label="item.name" :value="item.clientId"></el-option>
        </el-select>
      </el-col>
    </el-row>
    <div style="min-width: 400px;">
      <el-row>
        <el-col :span="10">
          <div style="text-align: left; padding: 5px 15px; font-size: 16px; background-color: #777; color: #fff; font-weight: 600; border-bottom: 1px solid #555;">店櫃名稱</div>
        </el-col>
        <el-col :span="10">
          <div style="text-align: left; padding: 5px 15px; font-size: 16px; background-color: #777; color: #fff; font-weight: 600; border-bottom: 1px solid #555;">店櫃號碼</div>
        </el-col>
        <el-col :span="4">
          <div style="text-align: left; padding: 5px 15px; font-size: 16px; background-color: #777; color: #fff; font-weight: 600; border-bottom: 1px solid #555;">操作</div>
        </el-col>
      </el-row>
      <div style="height: calc(100vh - 280px); overflow: auto; position: relative;">
        <SlickList lockAxis="y" v-model="saleroomList" :lockToContainerEdges="true" :distance="10">
          <SlickItem v-for="(element, index) in saleroomList" :index="index" :key="index + 's'" style="border: 1px solid #eee; background-color: #fff; cursor: pointer; height: 60px; overflow: hidden;">
            <el-row>
              <el-col :span="10" style="text-align: left;">
                <div style="padding-left: 14px; font-size: 16px; line-height: 60px;">{{ element.name }}</div>
              </el-col>
              <el-col :span="10" style="text-align: left;">
                <div style="padding-left: 16px; font-size: 16px; line-height: 60px;">{{ element.no }}</div>
              </el-col>
              <el-col :span="4" style="text-align: left;">
                <el-button style="margin: 13px 0 0 15px !important;" size="small" type="danger" @click="handleDel(index)">刪除</el-button>
              </el-col>
            </el-row>
          </SlickItem>
        </SlickList>
      </div>
    </div>
    <div style="text-align: right; margin-top: 10px;">
      <el-button type="primary" :disabled="!client" @click="addDia = true; addData.errorName = ''; addData.errorNo = ''">新增店櫃</el-button>
      <el-button type="warning" :disabled="!client" style="margin-right: 0 !important;" @click="saveUpdate">儲存變更</el-button>
    </div>
    <mu-dialog :open="addDia">
      <mu-text-field :error-text="addData.errorName" v-model.trim="addData.name" label="店櫃名稱"></mu-text-field><br/>
      <mu-text-field :error-text="addData.errorNo" v-model.trim="addData.no" label="店櫃號碼"></mu-text-field><br/>
      <mu-button slot="actions" flat color="primary" @click="addDia = false">取消</mu-button>
      <mu-button slot="actions" color="primary" @click="addSaleroom">確認</mu-button>
    </mu-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import { SlickList, SlickItem } from 'vue-slicksort';
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      pageLoading: false,
      addDia: false,
      saleroomList: [],
      emptyText: '尚無店櫃資料',
      addData: {
        name: '',
        errorName: '',
        no: '',
        errorNo: ''
      },
      enviro: 'pro',
      client: '',
      enviros: [
        { value: 'pro', name: '正式站' },
        { value: 'test', name: '測試站' }
      ],
    };
  },
  components: {
    SlickItem,
    SlickList
  },
  mounted() {
    if (this.clientList && this.clientList.length > 0) {
      // do something
    }
    else {
      this.getBrands();
    }
  },
  computed: {
    ...mapGetters({
      clientList: 'clients'
    })
  },
  methods: {
    ...mapActions({
      getBrands: 'getBrands',
      setSaleroom: 'setSaleroom',
      getClientData: 'getClientData'
    }),
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return 'background-color: #eee; font-weight: 500; color: #444;'
      }
    },
    saveUpdate() {
      this.pageLoading = true;
      const list = [{ name: '全省', no: '無' }].concat(this.saleroomList);
      this.setSaleroom({ enviro: this.enviro, clientId: this.client, salerooms: list }).then(() => {
        this.pageLoading = false;
        this.$notify({
          title: '更新成功',
          type: 'success'
        });
      })
    },
    handleDel(index) {
      this.saleroomList.splice(index, 1);
    },
    clearData() {
      this.client = '';
      this.saleroomList = [];
    },
    getSaleroom() {
      this.pageLoading = true;
      this.saleroomList = [];
      this.emptyText = '載入中...';
      this.getClientData({ enviro: this.enviro, clientId: this.client })
        .then((_data) => {
          this.pageLoading = false;
          this.emptyText = '尚無店櫃資料';
          this.saleroomList = _data.result.salerooms.filter(el => el.no !== '無');
        })
    },
    addSaleroom() {
      if (!this.addData.name) {
        this.addData.errorName = '請填寫店櫃名稱！';
      }
      if (!this.addData.no) {
        this.addData.errorNo = '請填寫店櫃號碼！';
      }
      if (this.addData.name && this.addData.no) {
        this.saleroomList.push({
          name: this.addData.name,
          no: this.addData.no
        });
        this.addDia = false;
      }
    }
  }
}
</script>

<style scoped>
  .delete_btn:hover {
    color: #f24;
  }
  .delete_btn {
    font-size: 16px;
    cursor: pointer;
    transition: 0.3s;
  }
</style>