<template>
  <div style="padding: 20px" v-loading.fullscreen.lock="pageLoading"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.7)">
    <div style="text-align: left;">
      <el-select style="margin-bottom: 10px; margin-right: 10px;" v-model="enviro" placeholder="請選擇環境" @change="clearData">
        <el-option v-for="item in enviros" :key="item.value" :label="item.name" :value="item.value"></el-option>
      </el-select>
      <el-button type="primary" icon="el-icon-plus" @click="addDia = true;" plain>新增品牌</el-button>
      <el-button type="info" icon="el-icon-refresh" @click="refresh" plain>刷新</el-button>
    </div>
    <el-table style="border: 1px solid #eee; padding: 0;" height="calc(100vh - 300px)" key="list" :data="clientList" :header-cell-style="tableHeaderColor" :empty-text="emptyText">
      <el-table-column prop="name"
        label="品牌名稱">
      </el-table-column>
      <el-table-column prop="title"
        label="品牌縮寫">
      </el-table-column>
      <el-table-column prop="clientId"
        label="ID">
      </el-table-column>
      <el-table-column prop="ratio"
        label="點數兌換率">
      </el-table-column>
      <el-table-column v-if="enviro === 'pro'" label="資料表" width="80">
        <template slot-scope="scope">
        <el-button @click="checkTable(scope.row.clientId)"
          type="text"
          style="margin: 0 !important;">查看
        </el-button>
      </template>
      </el-table-column>
    </el-table>
    <!-- 資料表 -->
    <mu-dialog :open="showSheet" @close="editRow = ''" width="550">
      <el-table style="border: 1px solid #eee; padding: 0;" key="list" :data="sheetTable" :header-cell-style="tableHeaderColor" :empty-text="emptyText">
        <el-table-column prop="type"
          width="150"
          label="資料表類型">
          <template slot-scope="scope">
            <span v-if="editRow !== scope.$index">{{ scope.row.type }}</span>
            <mu-select style="width: 80%; margin-bottom: 0 !important; font-size: 14px !important;" v-else v-model="sheetTable[scope.$index].type" placeholder="選擇類型">
              <mu-option v-for="item in filterList()"
                :key="item"
                :label="item"
                :value="item">
              </mu-option>
            </mu-select>
          </template>
        </el-table-column>
        <el-table-column prop="id"
          label="資料表 ID">
          <template slot-scope="scope">
            <span v-if="editRow !== scope.$index">{{ scope.row.id }}</span>
            <mu-text-field :disabled="!sheetTable[scope.$index].type" style="width: 90%; margin-bottom: 0 !important; font-size: 14px !important;" v-else v-model="sheetTable[scope.$index].id" :placeholder="getText(sheetTable[scope.$index].type)"></mu-text-field><br/>
          </template>
        </el-table-column>
        <el-table-column width="120">
          <template slot-scope="scope">
            <el-button @click="checkDel(scope.row, scope.$index)" v-if="editRow !== scope.$index"
              type="danger" size="small" style="margin: 0 !important;">刪除
            </el-button>
            <div v-else>
              <el-button @click="sendTable" :disabled="!sheetTable[scope.$index].id" plain
                type="success" size="small" icon="el-icon-check" style="margin-bottom: 0 !important;">
              </el-button>
              <el-button @click="sheetTable.splice(scope.$index, 1)" plain
                type="danger" size="small" icon="el-icon-close" style="margin: 0 !important;">
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 10px; text-align: center;">
        <el-button :disabled="sheetTable.length >= 4 || editRow !== '' || emptyText === '載入中...'" icon="el-icon-plus" circle @click="addNewSheet"></el-button>
      </div>
      <mu-button slot="actions" flat color="primary" @click="showSheet = false">關閉</mu-button>
    </mu-dialog>
    <!-- 刪除 -->
    <mu-dialog :open="deleteDia" width="550">
      <div>確定要刪除 <b>{{ deleteType }}</b> 嗎？</div>
      <mu-button slot="actions" flat color="primary" @click="deleteDia = false">返回</mu-button>
      <mu-button slot="actions" color="primary" @click="deleteSheet">確認</mu-button>
    </mu-dialog>
    <!-- 增加 -->
    <mu-dialog :open="addDia" title="新增品牌" width="550">
      <div style="height: calc(100vh - 350px); overflow: auto;">
        <mu-text-field :error-text="errorMsg1" placeholder="例：1654333455" full-width v-model.trim="addData.clientId" label="Client ID"></mu-text-field><br/>
        <mu-text-field :error-text="errorMsg2" placeholder="例：Whittard" full-width v-model.trim="addData.name" label="品牌名稱"></mu-text-field><br/>
        <mu-text-field :error-text="errorMsg3" placeholder="例：WH" full-width v-model.trim="addData.title" label="品牌縮寫"></mu-text-field><br/>
        <mu-text-field :error-text="errorMsg4" placeholder="例：100" full-width v-model.number="addData.ratio" label="點數兌換率（元/點）"></mu-text-field><br/>
        <mu-text-field placeholder="例：1654333455" full-width v-model.trim="addData.lineId" label="綁定 LINE ID（選填）"></mu-text-field><br/>
        <mu-text-field placeholder="例：685498085134680" full-width v-model.trim="addData.fbId" label="綁定 FB ID（選填）"></mu-text-field><br/>
        <mu-text-field v-if="enviro === 'pro'" placeholder="例：whittard" full-width v-model.trim="addData.dataSheet" label="會員總表 ID（選填）"></mu-text-field><br/>
        <mu-text-field v-if="enviro === 'pro'" placeholder="例：whittard_order" full-width v-model.trim="addData.orderSheet" label="消費紀錄表 ID（選填）"></mu-text-field><br/>
        <mu-text-field v-if="enviro === 'pro'" placeholder="例：whittard_exchange" full-width v-model.trim="addData.exchangeSheet" label="兌換紀錄表 ID（選填）"></mu-text-field><br/>
        <mu-text-field v-if="enviro === 'pro'" placeholder="例：whittard_send" full-width v-model.trim="addData.sendSheet" label="贈點紀錄表 ID（選填）"></mu-text-field><br/>
      </div>
      <mu-button slot="actions" flat color="primary" @click="addDia = false">返回</mu-button>
      <mu-button slot="actions" color="primary" @click="addClient">開通</mu-button>
    </mu-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      pageLoading: false,
      enviro: 'pro',
      client: '',
      emptyText: '尚無開通',
      enviros: [
        { value: 'pro', name: '正式站' },
        { value: 'test', name: '測試站' }
      ],
      dataList: ['會員總表', '兌換記錄', '消費記錄', '贈點記錄'],
      editRow: '',
      addDia: false,
      showSheet: false,
      deleteDia: false,
      deleteIdx: '',
      deleteType: '',
      deleteId: '',
      sheetTable: [],
      clientList: [],
      errorMsg1: '',
      errorMsg2: '',
      errorMsg3: '',
      errorMsg4: '',
      addData: {
        enviro: '',
        clientId: '',
        lineId: '',
        fbId: '',
        name: '',
        title: '',
        ratio: 50,
        dataSheet: '',
        orderSheet: '',
        exchangeSheet: '',
        sendSheet: ''
      }
    };
  },
  mounted() {
    this.refresh();
  },
  methods: {
    ...mapActions({
      getBrands: 'getBrands',
      getSheetName: 'getSheetName',
      enableClient: 'enableClient',
      addTable: 'addTable',
      deleteTable: 'deleteTable'
    }),
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return 'background-color: #eee; font-weight: 500; color: #444;'
      }
    },
    addNewSheet() {
      this.sheetTable.push({ type: '', id: '' });
      this.editRow = this.sheetTable.length - 1;
    },
    filterList() {
      const nowList = this.sheetTable.map(el => el.type);
      return this.dataList.filter(el => nowList.indexOf(el) === -1);
    },
    checkDel(row, idx) {
      this.deleteDia = true;
      this.deleteIdx = idx;
      this.deleteType = row.type;
      this.deleteId = row.id;
    },
    getType(type) {
      if (type === '會員總表') {
        return 'dataset';
      }
      if (type === '兌換記錄') {
        return 'exchange'
      }
      if (type === '消費記錄') {
        return 'order';
      }
      if (type === '贈點記錄') {
        return 'send';
      }
      return '';
    },
    sendTable() {
      const addSheetData = this.sheetTable[this.editRow];
      const postData = {
        clientId: this.client, dataSheet: addSheetData.id, dataType: this.getType(addSheetData.type)
      };
      this.editRow = '';
      this.addTable(postData);
    },
    deleteSheet() {
      this.deleteDia = false;
      this.sheetTable.splice(this.deleteIdx, 1);
      const postData = {
        clientId: this.client, dataSheet: this.deleteId, dataType: this.getType(this.deleteType)
      };
      this.deleteTable(postData);
    },
    refresh() {
      this.emptyText = '載入中...';
      this.getBrands({ enviro: this.enviro }).then((res) => {
        this.emptyText = '尚無開通';
        this.clientList = res.result;
      })
    },
    addClient() {
      this.addData.enviro = this.enviro;
      this.errorMsg1 = this.addData.clientId ? '' : 'Client ID 不得為空值';
      this.errorMsg2 = this.addData.name     ? '' : '品牌名稱不得為空值';
      this.errorMsg3 = this.addData.title    ? '' : '品牌縮寫不得為空值';
      this.errorMsg4 = this.addData.ratio    ? '' : '點數兌換率不得為空值或 0';
      // 確認參數
      if (this.addData.clientId && this.addData.name && this.addData.title && this.addData.ratio) {
        this.addDia = false;
        const loading = this.$loading({ 
          lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)'
        });
        this.enableClient(this.addData).then(() => {
          loading.close();
          this.refresh();
          this.$notify({ title: '開通成功', type: 'success' });
        })
      }
    },
    getText(type) {
      if (type === '會員總表') {
        return '例: whittard';
      }
      if (type === '兌換記錄') {
        return '例: whittard_exchange';
      }
      if (type === '消費記錄') {
        return '例: whittard_order';
      }
      if (type === '贈點記錄') {
        return '例: whittard_send';
      }
      return '';
    },
    openLink(id) {
      window.open(`https://console.cloud.google.com/bigquery?hl=zh-tw&project=decisive-coda-285307&p=decisive-coda-285307&d=ruentex556&t=${id}&page=table`);
    },
    checkTable(clientId) {
      this.client = clientId;
      this.sheetTable = [];
      this.showSheet = true;
      this.emptyText = '載入中...';
      this.getSheetName({ enviro: this.enviro, clientId: clientId }).then((_sheets) => {
        this.emptyText = '尚無開通';
        const sheetList = _sheets.result;
        const types = ['會員總表', '兌換記錄', '消費記錄', '贈點記錄'];
        for (let i = 0; i < sheetList.length; i += 1) {
          if (sheetList[i]) {
            this.sheetTable.push({
              type: types[i],
              id: sheetList[i]
            })
          }
        }
      })
    },
    clearData() {
      this.clientList = [];
      this.refresh();
    }
  }
}
</script>

<style scoped>
  .delete_btn:hover {
    color: #f24;
  }
  .delete_btn {
    font-size: 16px;
    cursor: pointer;
    transition: 0.3s;
  }
</style>