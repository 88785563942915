<template>
  <div style="padding: 20px; padding-bottom: 10px; min-width: 700px;" v-loading.fullscreen.lock="pageLoading"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.7)">
    <div style="text-align: left; margin-bottom: 5px;">
      <!-- 選擇環境 -->
      <el-row :gutter="10">
        <el-col :span="5">
          <el-select v-model="enviro" placeholder="請選擇環境" @change="clearData" style="width: 100%">
            <el-option
              v-for="item in enviros"
              :key="item.value"
              :label="item.name"
              :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <!-- 選擇品牌 -->
        <el-col :span="5">
          <el-select v-model="client" placeholder="請選擇品牌" style="width: 100%">
            <el-option
              v-for="item in clientList"
              :key="item.clientId"
              :label="item.name"
              :value="item.clientId">
            </el-option>
          </el-select>
        </el-col>
        <!-- 選擇方式 -->
        <el-col :span="5">
          <el-select v-model="type" placeholder="請選擇" style="width: 100%">
            <el-option
              v-for="item in types"
              :key="item.val"
              :label="item.label"
              :value="item.val">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="5">
          <el-input v-model="phone" :placeholder="type === 'phone' ? '請輸入會員電話' : '請輸入會員編號'"></el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" icon="el-icon-search" :disabled="!client || !phone" @click="getUser">查詢</el-button>
        </el-col>
      </el-row>
    </div><hr style="margin-right: 10px;" v-if="isUser">
    <div style="height: calc(100vh - 270px); overflow-y: auto; width: 100%; max-height: 550px;" v-if="isUser">
      <el-row>
        <el-col :span="5" style="border-right: 1px solid;">
          <h3 v-for="col in columns" :key="col" style="text-align: left;">{{ col }}</h3>
        </el-col>
        <el-col :span="19" style="padding-left: 20px;" v-if="isUser">
          <h3 style="text-align: left;"><span style="color: #409EFF;">{{ user.userId }}</span></h3>
          <h3 style="text-align: left;"><span style="color: #409EFF;">{{ user.name }}</span></h3>
          <h3 style="text-align: left;">
            <span style="color: #409EFF;">{{ user.phone }}</span>
            <i v-if="isUser && user.phone" class="el-icon-edit" style="cursor: pointer; margin-left: 10px;" @click="getEditDia('phone')"></i>
          </h3>
          <h3 style="text-align: left;"><span style="color: #409EFF;">{{ user.birth ? user.birth : '-' }}</span></h3>
          <h3 style="text-align: left;"><span style="color: #409EFF;">{{ user.email ? user.email : '-' }}</span></h3>
          <h3 style="text-align: left;"><span style="color: #409EFF;">{{ user.gender ? user.gender : '-' }}</span></h3>
          <h3 style="text-align: left;"><span style="color: #409EFF;">{{ user.point ? user.point : 0 }}</span></h3>
          <h3 style="text-align: left;"><span style="color: #409EFF;">{{ user.addTime ? getTimeFormat(user.addTime) : '-' }}</span></h3>
          <h3 style="text-align: left;"><span style="color: #409EFF;">{{ user.address ? user.address : '-' }}</span></h3>
          <h3 style="text-align: left;"><span style="color: #409EFF;">{{ (user.detail && user.detail.cardkind) ? user.detail.cardkind : '-' }}</span></h3>
          <h3 style="text-align: left;"><span style="color: #409EFF;">{{ (user.detail && user.detail.saleroom) ? user.detail.saleroom : '-' }}</span></h3>
          <h3 style="text-align: left;">
            <span style="color: #409EFF;">{{ (user.service && user.service.LINE) ? user.service.LINE.id : '-' }}</span>
            <i v-if="isUser && user.service.LINE" class="el-icon-edit" style="cursor: pointer; margin-left: 10px;" @click="getEditDia('lineId')"></i>
          </h3>
          <h3 style="text-align: left;">
            <span style="color: #409EFF;">{{ (user.service && user.service.FB) ? user.service.FB.id : '-' }}</span>
            <i v-if="isUser && user.service.FB" class="el-icon-edit" style="cursor: pointer; margin-left: 10px;" @click="getEditDia('fbId')"></i>
          </h3>
        </el-col>
      </el-row>
    </div><hr style="margin-right: 10px;" v-if="isUser">
    <!-- 按鈕 -->
    <div style="text-align: right; margin-top: 15px;" v-if="isUser">
      <el-button type="success" :disabled="isUser || !phone || enviro === 'test'" @click="checkDelBigquery = true">刪除數據後台資料</el-button>
      <el-button type="danger" :disabled="!isUser" @click="checkDel">刪除會員</el-button>
      <el-button type="primary" :disabled="!isUser" @click="sendPointDia = true;">贈送點數</el-button>
      <el-button type="warning" :disabled="!isUser" @click="updateUser">儲存變更</el-button>
    </div>
    <!-- 贈送點數 -->
    <mu-dialog :open.sync="sendPointDia">
      <div>會員姓名: <span style="color: #409EFF;"><b>{{ user.name }}</b></span></div>
      <div style="margin-top: 15px;">當前點數: <span style="color: #409EFF;"><b>{{ user.point }}</b></span></div>
      <div style="margin-top: 15px;">欲贈送點數:</div>
      <mu-text-field v-model.number="givedPoint" style="margin-bottom: 0;"></mu-text-field>
      <div style="margin-top: 5px;">備註:</div>
      <mu-text-field v-model.trim="remark"></mu-text-field><br/>
      <el-button type="primary" style="width: 100%;" :loading="isLoading" @click="confirmSend">
        <span v-if="!isLoading">確認</span>
      </el-button>
    </mu-dialog>
    <!-- 修改資料 -->
    <mu-dialog :open.sync="editDia" width="500">
      <mu-text-field full-width v-if="nowEdit === 'phone'" v-model="user.phone" label="電話" label-float></mu-text-field>
      <mu-text-field full-width v-if="nowEdit === 'lineId'" v-model="user.service.LINE.id" label="LINE ID" label-float></mu-text-field>
      <mu-text-field full-width v-if="nowEdit === 'fbId'" v-model="user.service.FB.id" label="FB ID" label-float></mu-text-field>
      <mu-button slot="actions" color="primary" @click="editDia = false">確認</mu-button>
      <div style="color: #f24;">{{ errorText }}</div>
    </mu-dialog>
    <!-- 刪除 -->
    <mu-dialog :open.sync="deleteDia">
      <div>確定要刪除會員 <b>{{ user.name }}</b> 嗎？</div>
      <mu-button slot="actions" color="primary" @click="sendDelete">確認</mu-button>
    </mu-dialog>
    <!-- 刪除 -->
    <mu-dialog :open.sync="checkDelBigquery" width="400">
      <div>確定要刪除嗎？</div>
      <mu-button slot="actions" color="primary" @click="deleteBigquery">確認</mu-button>
    </mu-dialog>
    <FlashMessage></FlashMessage>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      phone: '',
      enviro: 'pro',
      client: '1654333455',
      nowEdit: '',
      givedPoint: '',
      userPhone: '',
      remark: '',
      checkDelBigquery: false,
      editDia: false,
      deleteDia: false,
      isUser: false,
      pageLoading: false,
      sendPointDia: false,
      isLoading: false,
      errorText: '',
      user: {},
      columns: ['會員編號', '姓名', '電話', '生日', 'Email', '性別', '點數', '加入時間', '地址', '等級', '櫃位', 'LINE ID', 'FB ID'],
      enviros: [
        { value: 'pro', name: '正式站' },
        { value: 'test', name: '測試站' }
      ],
      type: 'phone',
      types: [
        { val: 'phone', label: '電話' },
        { val: 'userId', label: '會員編號' }
      ]
    };
  },
  mounted() {
    if (this.clientList && this.clientList.length > 0) {
      // do something
    }
    else {
      this.getBrands();
    }
  },
  computed: {
    ...mapGetters({
      clientList: 'clients'
    })
  },
  methods: {
    ...mapActions({
      getUserByPhone: 'getUserByPhone',
      deleteUser: 'deleteUser',
      getBrands: 'getBrands',
      sendPoint: 'sendPoint',
      deleteUserFromBigquery: 'deleteUserFromBigquery',
      updateMemberData: 'updateMemberData',
    }),
    showNotify(title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type
      });
    },
    clearData() {
      this.phone = '';
      this.client = '';
      this.isUser = false;
      this.user = {};
    },
    checkDel() {
      this.deleteDia = true;
    },
    getTimeFormat(time) {
      const nowtime = time ? new Date(time) : new Date();
      const now = nowtime.toLocaleDateString('zh', { timeZone: 'Asia/Taipei' });
      // 取得個別值
      let year = '';
      let month = '';
      let date = '';
      let usableMonth = '';
      let usableDate = '';
      // 取得對應時間
      year = now.split('/')[0];
      month = now.split('/')[1];
      date = now.split('/')[2];
      // 修正時間格式
      usableMonth = parseInt(month) < 10 ? `0${month}` : month;
      usableDate = parseInt(date) < 10 ? `0${date}` : date;
      // 產出時間
      return `${year}-${usableMonth}-${usableDate}`;
    },
    deleteBigquery() {
      this.checkDelBigquery = false;
      let postData = {};
      if (this.type === 'phone') {
        postData = {
          clientId: this.client,
          phone: this.phone
        };
      }
      else {
        postData = {
          clientId: this.client,
          userId: this.phone
        };
      }
      this.deleteUserFromBigquery(postData).then((_res) => {
        if (_res.status === '10020') {
          this.showNotify(_res.result, '', 'success');
        }
        else {
          this.showNotify(_res.result, '', 'warning');
        }
      })
    },
    confirmSend() {
      this.errorText = '';
      this.isLoading = true;
      this.sendPointDia = false;
      const postData = {
        enviro: this.enviro,
        clientId: this.client,
        phone: this.user.phone,
        point: this.givedPoint,
        remark: this.remark
      };
      this.sendPoint(postData).then((res) => {
        this.isLoading = false;
        if (res.status === '10020') {
          this.errorText = '';
          this.getUser();
          this.$notify({ title: '已更新點數', type: 'success' });
        }
        else {
          this.errorText = res.result;
        }
      })
    },
    sendDelete() {
      this.deleteDia = false;
      const loading = this.$loading({ lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)' });
      const postData = {
        enviro: this.enviro,
        clientId: this.client,
        phone: this.user.phone
      }
      this.deleteUser(postData).then(() => {
        loading.close();
        this.getUser();
      })
    },
    updateUser() {
      this.editDia = false;
      const postData = {
        enviro: this.enviro,
        clientId: this.client,
        custId: this.user.userId,
        phone: this.user.phone,
        point: this.user.point,
        lineId: (this.user.service && this.user.service.LINE) ? this.user.service.LINE.id : '',
        fbId: (this.user.service && this.user.service.FB) ? this.user.service.FB.id : ''
      };
      const loading = this.$loading({ lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)' });
      this.updateMemberData(postData).then((res) => {
        loading.close();
        if (res.status === '10020') {
          this.getUser();
          this.$notify({
            title: '更新成功',
            type: 'success'
          });
        }
        else {
          this.user.phone = this.userPhone;
          this.$notify({
            title: res.result,
            type: 'error'
          });
        }
      })
    },
    getEditDia(val) {
      this.editDia = true;
      this.nowEdit = val;
    },
    getUser() {
      this.isUser = false;
      const postData = { clientId: this.client, phone: (this.type === 'phone') ? this.phone : '', userId: (this.type === 'userId') ? this.phone : '', enviro: this.enviro };
      // 延時 loading
      setTimeout(() => { this.pageLoading = true }, 150);
      this.getUserByPhone(postData).then((res) => {
        this.pageLoading = false;
        if (res.status === '10020') {
          this.isUser = true;
          this.user = res.result;
          this.userPhone = res.result.phone;
        }
        else {
          this.isUser = false;
          this.$notify({
            title: '查無此會員',
            type: 'error'
          });
        }
      })
    }
  }
}
</script>

<style scoped>

</style>