<template>
  <div style="padding: 20px; padding-bottom: 10px;">
    <div style="text-align: left; margin-bottom: 5px;">
      <!-- 選擇環境 -->
      <el-row :gutter="10">
        <el-col :span="6">
          <el-select v-model="enviro" placeholder="請選擇環境" @change="clearData" style="width: 100%;">
            <el-option v-for="item in enviros"
              :key="item.value"
              :label="item.name"
              :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="6">
          <!-- 選擇品牌 -->
          <el-select v-model="client" placeholder="請選擇品牌" style="width: 100%;">
            <el-option v-for="item in clientList"
              :key="item.clientId"
              :label="item.name"
              :value="item.clientId">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="6">
          <!-- 選擇優惠案號 -->
          <el-input v-model="itemNo" placeholder="輸入優惠案號"></el-input>
        </el-col>
        <el-col :span="6">
          <el-button type="primary" icon="el-icon-search" @click="getItem" :disabled="!client || !itemNo">查詢</el-button>
        </el-col>
      </el-row>
    </div>
    <!-- 優惠券列表 -->
    <div>
      <div style="text-align: right;">
        數量：{{ codeTable.length }}
      </div>
      <el-table ref="multipleTable" @selection-change="handleSelectionChange" height="calc(100vh - 300px)" :data="codeTable" :header-cell-style="tableHeaderColor" empty-text="暫無優惠券序號">
        <!-- 名稱 -->
        <el-table-column label="優惠券序號">
          <template slot-scope="scope">
            <div style="color: #466594; font-weight: 600; font-size: 15px;">{{ scope.row[0] }}</div>
          </template>
        </el-table-column>
        <!-- 使用狀況 -->
        <el-table-column label="使用狀況" width="170">
          <template slot-scope="scope">
            <el-tag v-if="scope.row[1] === 'true'">可使用</el-tag>
            <el-tag type="info" v-else>已領取</el-tag>
          </template>
        </el-table-column>
        <!-- 操作 -->
        <el-table-column
          type="selection"
          width="55">
        </el-table-column>
      </el-table>
      <div style="text-align: right; padding-top: 15px;">
        <el-button icon="el-icon-delete" type="danger" @click="checkDelete" :disabled="multipleSelection.length < 1">刪除</el-button>
        <el-button icon="el-icon-plus" type="primary" style="margin-right: 0 !important;" @click="showAddDia" :disabled="!client || !itemNo">新增</el-button>
      </div>
    </div>
    <mu-dialog :open="deleteDia" width="350">
      確定要刪除這些優惠券嗎？
      <mu-button slot="actions" flat color="primary" @click="deleteDia = false">返回</mu-button>
      <mu-button slot="actions" color="primary" @click="sendDelete">確認</mu-button>
    </mu-dialog>
    <mu-dialog :open.sync="addDia">
      <div>請輸入要產生的優惠券數量</div>
      <mu-text-field v-model.number="couponNum"></mu-text-field>
      <mu-button slot="actions" color="primary" @click="sendGenerate">確認</mu-button>
    </mu-dialog>
    <mu-dialog :open.sync="successDia" width="550">
      <div>正在產生優惠券中，需要數分鐘的時間，請稍後再按“查詢”確認。</div>
    </mu-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      enviro: 'pro',
      client: '',
      itemNo: '',
      itemNos: [],
      enviros: [
        { value: 'pro', name: '正式站' },
        { value: 'test', name: '測試站' }
      ],
      codeTable: [],
      multipleSelection: [],
      deleteDia: false,
      addDia: false,
      successDia: false,
      couponNum: 0,
      brands: {
        '1654333455': 'WH',
        '1655280896': 'EP',
        '1655682944': 'JP',
        '1655953868': 'NS',
        '1656289509': 'LP'
      }
    };
  },
  computed: {
    ...mapGetters({
      clientList: 'clients',
    })
  },
  methods: {
    ...mapActions({
      getCodeList: 'getCodeList',
      deleteCode: 'deleteCode',
      generateCoupon: 'generateCoupon'
    }),
    clearData() {
      this.client = '';
      this.itemNo = '';
      this.codeTable = [];
      this.multipleSelection = [];
    },
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return 'background-color: #eee; font-weight: 500; color: #444;'
      }
    },
    showAddDia() {
      this.addDia = true;
    },
    sendGenerate() {
      this.addDia = false;
      const loading = this.$loading({ lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)' });
      this.generateCoupon({ clientId: this.client, brand: this.brands[this.client], registerno: this.itemNo, mode: 'OFFLINE', count: this.couponNum, enviro: this.enviro })
        .then(() => {
          loading.close();
          this.couponNum = 0;
          this.successDia = true;
        })
    },
    getItem() {
      this.multipleSelection = [];
      const loading = this.$loading({ lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)' });
      this.getCodeList({ clientId: this.client, itemNo: this.itemNo, enviro: this.enviro })
        .then((res) => {
          loading.close();
          if (res.result) {
            this.codeTable = Object.entries(res.result).sort((a, b) => b[1].indexOf(true) - a[1].indexOf(true));
          }
          else {
            this.codeTable = [];
          }
        })
    },
    sendDelete() {
      this.deleteDia = false;
      const loading = this.$loading({ lock: true, text: 'Loading', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)' });
      const deleteList = this.multipleSelection.map(el => el[0]);
      let promise = [];
      for (let i = 0; i < deleteList.length; i += 1) {
        promise.push(this.deleteCode({ clientId: this.client, itemNo: this.itemNo, code: deleteList[i], enviro: this.enviro }))
      }
      Promise.all(promise).then(() => {
        this.getItem();
      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    checkDelete() {
      this.deleteDia = true;
    }
  }
}
</script>

<style scoped>

</style>