<template>
  <div style="padding: 20px;" v-loading.fullscreen.lock="pageLoading"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.7)">
    <div style="text-align: left; margin-bottom: 5px; width: 100%;">
      <el-row :gutter="10" style="max-width: 1000px;">
        <!-- 選擇環境 -->
        <el-col :span="8">
          <el-select style="margin-bottom: 10px; width: 100%;" v-model="enviro" placeholder="請選擇環境" @change="clearData">
            <el-option v-for="item in enviros" :key="item.value" :label="item.name" :value="item.value"></el-option>
          </el-select>
        </el-col>
        <!-- 選擇品牌 -->
        <el-col :span="8">
          <el-select style="margin-bottom: 10px; width: 100%;" v-model="client" placeholder="請選擇品牌" @change="getAllTags">
            <el-option v-for="item in clientList" :key="item.clientId" :label="item.name" :value="item.clientId"></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row :gutter="10" style="min-width: 800px; max-width: 1200px; margin-top: 5px;">
        <el-col :span="13">
          <h3 style="margin-top: 0; margin-bottom: 0; text-align: center; background: #555; color: #fff; padding: 5px;">標籤列表</h3>
          <el-table style="border: 1px solid #eee; padding: 0;" height="calc(100vh - 350px)" :key="Math.random()" :data="tagList" :header-cell-style="tableHeaderColor" :empty-text="emptyText">
            <el-table-column prop="name"
              label="標籤名稱">
            </el-table-column>
            <el-table-column prop="count"
              label="人數" width="100"></el-table-column>
            <el-table-column prop="count"
              width="130">
              <template slot-scope="scope">
                <el-button size="small" type="primary" style="margin: 0 !important; margin-right: 7px !important;" @click="checkList(scope.row.name, scope.$index)">名單</el-button>
                <el-button size="small" type="danger" style="margin: 0 !important;" icon="el-icon-delete" @click="checkRemove(scope.row.name, scope.$index)"></el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-button :disabled="!client" @click="addTagDia = true" icon="el-icon-plus" type="info" style="width: 100%; margin-top: 10px;">新增標籤</el-button>
        </el-col>
        <!-- 會員 -->
        <el-col :span="11">
          <h3 style="margin-top: 0; margin-bottom: 0; text-align: center; background: #555; color: #fff; padding: 5px;">查詢會員標籤</h3>
          <div style="height: calc(100vh - 300px); overflow: auto; border: 1px solid #eee; padding: 15px;">
            <mu-select v-model="type" style="width: 120px; margin-bottom: 5px;">
              <mu-option v-for="item in ['會員電話', '會員編號']"
                :key="item"
                :label="item"
                :value="item"></mu-option>
            </mu-select>
            <el-row :gutter="10">
              <el-col :span="16">
                <el-input v-model="searchVal" :placeholder="`請輸入${type}`"></el-input>
              </el-col>
              <el-col :span="8">
                <el-button style="width: 100%;" type="primary" :icon="showIcon" @click="searchUserTag" :disabled="!searchVal || !client">查詢</el-button>
              </el-col>
            </el-row>
            <!-- 標籤列表 -->
            <div style="color: rgba(0,0,0,.87); font-size: 16px; margin-top: 10px;">標籤 :</div>
            <div v-if="userTags.length > 0" style="margin-top: 15px;">
              <div v-for="(tag, index) in aval(userTags)" :key="index + 't'" class="blue_btn" style="margin-bottom: 10px;" @click="checkDel(tag)">
                <i class="el-icon-price-tag"></i>
                {{ tag }}
              </div>
            </div>
            <div v-else style="margin-top: 15px;">
              <div class="grey_btn" style="margin-bottom: 10px;">
                <i class="el-icon-price-tag"></i>
                尚無標籤
              </div>
            </div>
            <!-- 分隔線 -->
            <hr style="border: 1px solid #eee;">
            <!-- 分隔線 -->
            <div style="margin-top: 10px;">
              <el-row :gutter="10">
                <el-col :span="16">
                  <el-select v-model="addTag"
                    style="width: 100%;"
                    filterable
                    no-data-text="無資料"
                    placeholder="請選擇標籤"
                    default-first-option>
                    <el-option v-for="addtag in tagList.filter(el => userTags.indexOf(el.name) === -1)"
                      :key="addtag.name"
                      :label="addtag.name"
                      :value="addtag.name">
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="8">
                  <el-button :disabled="!searchVal || !client || !addTag" style="width: 100%;" type="primary" icon="el-icon-plus" @click="addUserTag">加入</el-button>
                </el-col> 
              </el-row>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 增加標籤 -->
    <mu-dialog :open.sync="addTagDia" width="300">
      <mu-text-field :error-text="warning" v-model="addTagName" label="標籤名稱"></mu-text-field><br/>
      <mu-button color="primary" style="width: 100%;" @click="addToTagList" :disabled="!addTagName">新增</mu-button>
    </mu-dialog>
    <!-- 移除帳號標籤 -->
    <mu-dialog :open="removeDia" width="450">
      <div>確定要移除標籤 <b>{{removeTag}}</b> 嗎？</div>
      <mu-button slot="actions" flat color="primary" @click="removeDia = false">取消</mu-button>
      <mu-button slot="actions" color="primary" @click="sendRemove">確認</mu-button>
    </mu-dialog>
    <!-- 移除會員標籤 -->
    <mu-dialog :open="deleteDia" width="450">
      <div>確定要移除標籤 <b>{{deleteTag}}</b> 嗎？</div>
      <mu-button slot="actions" flat color="primary" @click="deleteDia = false">取消</mu-button>
      <mu-button slot="actions" color="primary" @click="sendDelete">確認</mu-button>
    </mu-dialog>
    <mu-dialog :open.sync="tagDia" :title="page === 0 ? `分眾名單: ${seleteTag}` : '匯入名單'" width="550">
      <!-- 顯示名單 -->
      <el-table v-if="page === 0" height="calc(100vh - 300px)" :key="Math.random()" :data="userList" :header-cell-style="tableHeaderColor" :empty-text="emptyText">
        <el-table-column prop="userId"
          label="會員編號">
        </el-table-column>
        <el-table-column prop="name"
          label="會員姓名">
        </el-table-column>
      </el-table>
      <!-- 範例 -->
      <div v-else-if="page === 1">
        <h4 style="margin-top: 0; margin-bottom: 5px;">匯入範例格式如下圖</h4>
        <p style="font-size: 15px;">備註：檔案格式必須為.xlsx，每次匯入的名單上限為 <b>1000</b> 人</p>
        <img src="https://i.imgur.com/dRnDFbQ.png" width="70%">
        <div style="margin-top: 15px;">
          <el-button size="small" type="info" icon="el-icon-download" @click="export2Excel">下載範例檔</el-button>
        </div>
      </div>
      <!-- 上傳 -->
      <div v-else-if="page === 2">
        <label style="padding: 10px 20px; font-size: 14px; border-radius: 4px; cursor: pointer; background-color: #409EFF; color: #fff;">
          <input style="display:none;" type="file" @change="transformFile">點擊上傳檔案
        </label>
        <div style="display: inline-block; margin-left: 10px; transform: translateY(10px);">{{ fileName }}</div>
        <br><br>
        <div v-if="progress">
          <mu-linear-progress></mu-linear-progress>
        </div>
        <div style="color: #f24">{{ errorMsg }}</div>
      </div>
      <el-button type="primary"
        v-if="page === 0"
        @click="page = 1"
        icon="el-icon-user-solid"
        style="width: 100%; margin-top: 10px;">批次匯入名單
      </el-button>
      <!-- 返回 -->
      <mu-button v-if="page !== 0" slot="actions" flat color="primary" @click="page = page - 1">返回</mu-button>
      <mu-button v-if="page === 1" slot="actions" color="primary" @click="page = 2">下一步</mu-button>
      <mu-button v-if="page === 2" slot="actions" color="primary" @click="streamTag">確認</mu-button>
    </mu-dialog>
    <!-- 加載進度條 -->
    <mu-dialog :open="showLoadingBar">
      <p>正在匯入名單中...請勿關閉視窗</p>
      <mu-linear-progress :value="linear" mode="determinate" color="green"></mu-linear-progress>
    </mu-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import XLSX from 'xlsx';
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      tagList: [],
      userList: [],
      userTags: [],
      tagDia: false,
      linear: 0,
      seleteTag: '',
      showLoadingBar: false,
      addTagDia: false,
      deleteDia: false,
      removeDia: false,
      warning: '',
      addTagName: '',
      addTag: '',
      removeTag: '',
      removeIndex: 0,
      page: 0,
      deleteTag: '',
      userId: '',
      type: '會員電話',
      showIcon: 'el-icon-search',
      searchVal: '',
      enviro: 'pro',
      client: '',
      service: 'LINE',
      pageLoading: false,
      emptyText: '暫無資料',
      progress: false,
      errorMsg: '',
      fileName: '',
      sheetUser: [],
      sheetData: [
        { 'userId':'ELS000000122'},
        { 'userId':'ELS000000130'},
        { 'userId':'ELS000000117'}
      ],
      enviros: [
        { value: 'pro', name: '正式站' },
        { value: 'test', name: '測試站' }
      ],
    };
  },
  mounted() {
    if (this.clientList && this.clientList.length > 0) {
      // do something
    }
    else {
      this.getBrands();
    }
  },
  computed: {
    ...mapGetters({
      clientList: 'clients'
    })
  },
  methods: {
    ...mapActions({
      getBrands: 'getBrands',
      getTagUser: 'getTagUser',
      getClientData: 'getClientData',
      addUserToTag: 'addUserToTag',
      removeUserTag: 'removeUserTag',
      getUserByPhone: 'getUserByPhone',
      fixedTagNum: 'fixedTagNum',
      deletePageTag: 'deleteTag',
      streamTags: 'streamTags',
      addPageTag: 'addPageTag'
    }),
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return 'background-color: #eee; font-weight: 500; color: #444;'
      }
    },
    streamTag() {
      this.streamTags({ enviro: this.enviro, clientId: this.client, tag: this.seleteTag, userIds: this.sheetUser })
        .then(() => {
          this.getAllTags();
        })
      this.tagDia = false;
      this.showLoadingBar = true;
      let interval = 0;
      if (this.sheetUser.length >= 500) {
        interval = this.sheetUser.length;
      }
      else {
        interval = 500;
      }
      setInterval(() => {
        this.linear += 20;
        if (this.linear >= 100) {
          this.showLoadingBar = false;
        }
      }, interval);
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },
    export2Excel() {
      require.ensure([], () => {
        const { export_json_to_excel } = require('./excel/Export2Excel');
        const tHeader = ['userId'];  // 设置 Excel 的表格第一行的标题
        const filterVal = ['userId'];  // tableData 里对象的属性
        const list = this.sheetData;  //把 data 里的 tableData 存到 list
        const data = this.formatJson(filterVal, list);
        export_json_to_excel(tHeader, data, 'ruentex_tag_sample');  //导出 Excel 文件名
      })
    },
    transformFile(e) {
      this.errorMsg = '';
      e.preventDefault();
      let file = e.target.files;
      if (file.length <= 0) { // 如果沒有檔名
        return;
      } 
      else if (!/\.(xls|xlsx)$/.test(file[0].name.toLowerCase())) {
        this.errorMsg = '上傳格式不正確，請上傳xls或者xlsx格式';
        return;
      }
      let fileReader = new FileReader();
      if (typeof fileReader === 'undefined') {
        this.errorMsg = '您的瀏覽器不支援FileReader介面';
        return
      }
      var vm = this;
      vm.progress = true;
      fileReader.onload = function(ev) {
        try {
          var data = ev.target.result,
          workbook = XLSX.read(data, { type: 'binary' }), // 以二进制流方式读取得到整份excel表格对象
          persons = []; // 存储获取到的数据
        } 
        catch (e) {
          console.log(e);
          return;
        }
        var fromTo = '';
        for (var sheet in workbook.Sheets) {
          if (workbook.Sheets.hasOwnProperty(sheet)) {
            fromTo = workbook.Sheets[sheet]['!ref'];
            persons = persons.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
            break;
          }
        }
        if (persons.length <= 1000) {
          vm.sheetUser = persons.map(el => el.userId);
          vm.fileName = file[0].name;
          console.log(vm.sheetUser);
        }
        else {
          this.errorMsg = '上傳名單不能大於 1000 筆';
        }
        vm.progress = false;
      }
      // 以二进制方式打开文件
      fileReader.readAsBinaryString(file[0]);
    },
    clearData() {
      this.client = '';
      this.tagList = [];
      this.userTags = [];
    },
    addToTagList() {
      this.tagList = [];
      if (this.tagList.map(el => el.name).indexOf(this.addTagName) !== -1) {
        this.warning = '此標籤已存在!';
      }
      else {
        this.pageLoading = true;
        this.warning = '';
        this.addTagDia = false;
        this.addPageTag({ tag: this.addTagName, clientId: this.client, enviro: this.enviro })
          .then(() => {
            this.getClientData({ enviro: this.enviro, clientId: this.client, service: this.service })
              .then((_data) => {
                this.pageLoading = false;
                this.emptyText = '暫無標籤';
                if (_data.result.tagCount) {
                  const tagList = Object.entries(_data.result.tagCount);
                  for (let i = 0; i < tagList.length; i += 1) {
                    if (_data.result.tags[tagList[i][0]]) {
                      this.tagList.push({
                        name: tagList[i][0],
                        count: tagList[i][1]
                      })
                    }
                  }
                }
                else {
                  this.tagList = [];
                }
              })
          })
      }
    },
    checkRemove(val, index) {
      this.removeDia = true;
      this.removeTag = val;
      this.removeIndex = index;
    },
    sendRemove() {
      this.deletePageTag({ tag: this.removeTag, clientId: this.client, enviro: this.enviro })
      this.removeDia = false;
      this.tagList.splice(this.removeIndex, 1);
      if (this.tagList.length < 1) {
        this.emptyText = '暫無標籤';
      }
    },
    sendDelete() {
      this.deleteDia = false;
      this.removeUserTag({
        tag: this.deleteTag, userId: this.userId, service: this.service, clientId: this.client, enviro: this.enviro
      });
      const index_1 = this.tagList.map(el => el.name).indexOf(this.deleteTag);
      const index_2 = this.userTags.indexOf(this.deleteTag);
      this.userTags.splice(index_2, 1);
      this.tagList[index_1].count -= 1;
    },
    addUserTag() {
      if (this.userTags.indexOf(this.addTag) === -1) {
        this.addUserToTag({
          tag: this.addTag, userId: this.userId, service: this.service, clientId: this.client, enviro: this.enviro
        });
        this.userTags.push(this.addTag);
        const index = this.tagList.map(el => el.name).indexOf(this.addTag);
        if (index !== -1) {
          this.tagList[index].count += 1;
        }
        else {
          this.tagList.push({
            name: this.addTag,
            count: 1
          });
        }
        this.addTag = '';
      }
      else {
        this.$notify({
          title: '該會員已經有此標籤',
          type: 'warning'
        });
      }
    },
    checkDel(val) {
      this.deleteTag = val;
      this.deleteDia = true;
    },
    checkList(row, index) {
      this.emptyText = '載入中...';
      this.seleteTag = row;
      this.page = 0;
      this.tagDia = true;
      this.userList = [];
      const postData = { enviro: this.enviro, clientId: this.client, service: this.service, tag: row };
      this.getTagUser(postData).then((res) => {
        if (typeof res.result === 'string') {
          this.emptyText = '尚無會員';
          this.userList = [];
        }
        else if (res.result.length < 1) {
          this.emptyText = '尚無會員';
          this.userList = [];
        }
        else {
          this.userList = res.result;
        }
        const postData = {
          clientId: this.client,
          tag: row,
          count: this.userList.length
        };
        this.tagList[index].count = this.userList.length;
        this.fixedTagNum(postData);
      })
    },
    aval(tag) {
      const avalibleTagName = this.tagList.map(st => st.name);
      if (tag.length > 0) {
        return tag.filter(el => avalibleTagName.indexOf(el) !== -1)
      }
      else {
        return [];
      }
    },
    searchUserTag() {
      let postData = null;
      this.showIcon = 'el-icon-loading';
      if (this.type === '會員電話') {
        postData = { enviro: this.enviro, service: this.service, clientId: this.client, phone: this.searchVal };
      }
      else {
        postData = { enviro: this.enviro, service: this.service, clientId: this.client, userId: this.searchVal };
      }
      this.getUserByPhone(postData).then((res) => {
        this.showIcon = 'el-icon-search';
        if (res.result.userId) {
          this.userTags = res.result.tagList ? res.result.tagList : [];
          this.userId = res.result.userId;
        }
        else {
          this.userTags = [];
          this.$notify({
            title: res.result,
            type: 'warning'
          });
        }
      })
    },
    getAllTags() {
      this.tagList = [];
      this.emptyText = '載入中...';
      const postData = { enviro: this.enviro, clientId: this.client, service: this.service };
      this.getClientData(postData).then((_data) => {
        this.emptyText = '暫無標籤';
        if (_data.result.tagCount) {
          const tagList = Object.entries(_data.result.tagCount)
          for (let i = 0; i < tagList.length; i += 1) {
            if (_data.result.tags[tagList[i][0]] && tagList[i][0].indexOf('EILIS_BI') === -1) {
              this.tagList.push({
                name: tagList[i][0],
                count: tagList[i][1]
              })
            }
          }
        }
        else {
          this.tagList = [];
        }
      })
    }
  }
}
</script>

<style scoped>
  .grey_btn {
    background: rgba(128, 128, 128, 0.2);
    border: 1px solid rgba(128, 128, 128, 1);
    color: rgba(128, 128, 128, 1);
    transform: translateY(-3px);
    display: inline-block;
    padding: 2px 15px;
    font-size: 16px;
    margin-right: 10px;
    border-radius: 5px;
  }
  .blue_btn {
    background: rgba(48, 117, 229, 0.2);
    border: 1px solid rgba(48, 117, 229, 1);
    color: rgba(48, 117, 229, 1);
    transform: translateY(-3px);
    display: inline-block;
    padding: 2px 15px;
    font-size: 16px;
    margin-right: 10px;
    cursor: pointer;
    border-radius: 5px;
  }
  .blue_btn:hover {
    background: rgba(255, 28, 28, 0.2);
    border: 1px solid rgba(255, 28, 28, 1);
    color: rgba(255, 28, 28, 1);
    transform: translateY(-3px);
    display: inline-block;
    padding: 2px 15px;
    font-size: 16px;
    margin-right: 10px;
    cursor: pointer;
    border-radius: 5px;
  }
</style>