<template>
  <div style="margin-top: -20px;">
    <h3 style="color: #000; text-align: center; margin-top: 0;">題目設定</h3>
    <div v-if="info" style="height: calc(100vh - 380px); overflow-y: auto;">
      <div style="font-weight: 500;">
        <span>選擇題型</span>
        <span style="color: #f24;"> *</span>
        <el-select v-model="info.type"
          style="margin-left: 10px; margin-right: 20px;">
          <el-option
            v-for="item in types"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-switch
          v-if="info.type !== '4'"
          v-model="info.required"
          active-color="#13ce66"
          inactive-color="#ff4949"
          active-text="必填"
          inactive-text="非必填">
        </el-switch>
      </div><br>
      <!-- <div style="font-weight: 500;">
        <div style="margin-bottom: 5px;">題目編號（大小寫英文或數字）</div>
        <el-input :disabled="editQuesType === 'edit'" v-model.trim="info.id" placeholder="輸入題目編號"></el-input>
      </div> -->
      <!-- <div style="color: #f24;">{{ errorText }}</div>
      <br> -->
      <div style="font-weight: 500;">
        <div style="margin-bottom: 5px;">題目標題</div>
        <el-input v-model="info.title" placeholder="輸入題目標題文字"></el-input>
      </div><br>
      <div style="font-weight: 500;">
        <div style="margin-bottom: 5px;">題目圖片</div>
        <el-row :gutter="20" style="margin-top: 5px; width: 370px;">
          <el-col :span="10">
            <img :src="info.img || defaultImg" width="100%"/>
          </el-col>
          <el-col :span="14">
            <div style="height: 6px;"></div>
            <uploadImg
              :showText="true"
              @finishUpload="finishUploadOne"
              ratio="800 * 500"
              :size="1"
            />
          </el-col>
        </el-row>
      </div><br>
      <div style="font-weight: 500;">
        <div style="margin-bottom: 5px;">題目敘述</div>
        <el-input
          type="textarea"
          :rows="3"
          placeholder="輸入文字"
          v-model="info.desc"
          maxlength="100"
          show-word-limit
        />
      </div><br>
      <div style="font-weight: 500;" v-if="info.type === '1' || info.type === '2'">
        <div style="margin-bottom: 5px;">
          題目選項
          <span style="color: #f24; margin-right: 10px;"> *</span>
          <el-button size="small"
            @click="addItem"
            icon="el-icon-plus"
            type="primary" plain>
            新增選項
          </el-button>
          <el-switch
            @change="checkOther"
            v-model="info.other"
            active-color="#13ce66"
            inactive-color="#ff4949"
            inactive-text="其他選項">
          </el-switch>
        </div>
      </div>
      <div v-if="info.type === '1' || info.type === '2'">
        <el-row>
          <el-col v-for="(col, idx) in formTitle"
            :span="col.span" :key="idx + 'c'">
            <div class="rowTitle">{{ col.label }}</div>
          </el-col>
        </el-row>
        <SlickList lockAxis="y" axis="y" v-model="info.options" :lockToContainerEdges="true" :distance="10">
          <SlickItem v-for="(o, index) in info.options" :index="index" :key="index + 'o'" class="rowStyle">
            <el-row>
              <el-col :span="3" style="text-align: left;">
                <div class="rowContent">{{ index + 1 }}</div>
              </el-col>
              <el-col :span="9" style="text-align: left;">
                <div class="rowContent">
                  <el-input v-if="o.type === 'input'"
                    size="small"
                    v-model="o.value">
                  </el-input>
                  <div v-else>其他</div>
                </div>
              </el-col>
              <el-col :span="6" style="text-align: left;">
                <div class="rowContent" style="padding-top: 11px;">
                  <div v-if="o.type === 'input'">
                    <div v-if="!o.img" @click="getIndex(index)">
                      <uploadImg
                        @finishUpload="finishUploadTwo"
                        :showText="false"
                        :size="1"
                      />
                    </div>
                    <div v-else>
                      <img :src="o.img"
                        @click="checkDelImg(index)"
                        width="90%"
                      />
                    </div>
                  </div>
                </div>
              </el-col>
              <el-col :span="6" style="text-align: left;">
                <div class="rowContent">
                  <el-button type="danger"
                    @click="deleteItem(index)"
                    v-if="o.type === 'input'"
                    size="small"
                    style="margin: 0 !important;"
                    plain>
                    刪除
                  </el-button>
                </div>
              </el-col>
            </el-row>
          </SlickItem>
        </SlickList>
      </div>
    </div>
    <hr>
    <div style="text-align: right;" v-if="info">
      <el-button type="info"
        @click="closeDia"
        style="margin-bottom: 0 !important;">
        取消
      </el-button>
      <el-button type="warning"
        :disabled="!info.title"
        @click="saveQues"
        style="margin: 0 !important;">
        儲存
      </el-button>
    </div>
    <mu-dialog :open="deleteDia" width="450">
      <div>要刪除這張圖片嗎？</div>
      <mu-button slot="actions" flat color="primary" @click="deleteDia = false">取消</mu-button>
      <mu-button slot="actions" color="primary" @click="sendDelImg">確認</mu-button>
    </mu-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import { SlickList, SlickItem } from 'vue-slicksort';
import uploadImg from './uploadImg.vue';
import defaultImg from '@/assets/default-image.png';

export default {
  data() {
    return {
      info: null,
      defaultImg: defaultImg,
      uploadIdx: 0,
      deleteIdx: 0,
      types: [
        { value: '1', label: '單選題' },
        { value: '2', label: '複選題' },
        { value: '3', label: '多行文字' },
        { value: '4', label: '分類標題' }
      ],
      formTitle: [
        { label: '順序', span: 3 },
        { label: '選項名稱', span: 9 },
        { label: '選項圖片', span: 6 },
        { label: '操作', span: 6 }
      ],
      deleteDia: false,
      errorText: '',
    }
  },
  props: ['editInfo', 'editQuesType', 'allQuestion', 'nowSerial'],
  components: {
    SlickList,
    SlickItem,
    uploadImg
  },
  mounted() {
    this.info = this.editInfo;
    this.errorText = '';
  },
  methods: {
    closeDia() {
      this.$emit('closeDia', true);
    },
    getIndex(index) {
      this.uploadIdx = index;
    },
    finishUploadTwo(url) {
      this.info.options[this.uploadIdx].img = url;
    },
    finishUploadOne(url) {
      this.info.img = url;
    },
    deleteItem(idx) {
      this.info.options.splice(idx, 1);
    },
    addItem() {
      this.info.options.push({
        type: 'input',
        value: '',
        img: ''
      })
    },
    checkDelImg(index) {
      this.deleteIdx = index;
      this.deleteDia = true;
    },
    sendDelImg() {
      this.deleteDia = false;
      this.info.options[this.deleteIdx].img = '';
    },
    checkOther(val) {
      if (val) {
        this.info.options.push({
          type: 'other'
        })
      }
      else {
        this.info.options = this.info.options.filter(item => item.type !== 'other');
      }
    },
    saveQues() {
      let allowSend = true;
      if (allowSend) {
        const newSerial = this.nowSerial + 1;
        if (this.editQuesType !== 'edit') {
          this.info.id = newSerial;
        }
        this.$emit('saveQues', this.info);
      }
    }
  }
}

</script>

<style>

.rowStyle {
  border: 1px solid #eee;
  background-color: #fff;
  cursor: pointer;
  overflow: hidden;
}

.rowTitle {
  text-align: left;
  padding: 10px 15px;
  font-size: 14px;
  background-color: #eee;
  color: #777;
  font-weight: 600;
}

.rowContent {
  padding: 10px 14px;
  line-height: 30px;
  font-size: 14px;
}

</style>